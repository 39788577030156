import { Theme } from '@mui/material/styles';
import { createStyles } from '@mui/styles';
import { alpha } from '@mui/system/colorManipulator';
import custom from '../custom';
import { StyleRules } from '@mui/styles/withStyles';
import typography from '../typography';
import withPathPrefix from 'utils/withPathPrefix';

const styles = (theme: Theme): StyleRules => {
  return createStyles({
    row: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      marginLeft: -15,
      marginRight: -15,
    },
    col: {
      position: 'relative',
      width: '100%',
      paddingLeft: 15,
      paddingRight: 15,
    },
    colOneThird: {
      [theme.breakpoints.up('lg')]: {
        maxWidth: '33.333333%',
        flex: '0 0 33.333333%',
      },
    },
    colTwoThird: {
      [theme.breakpoints.up('lg')]: {
        maxWidth: '66.666667%',
        flex: '0 0 66.666667%',
      },
    },
    colOneHalf: {
      [theme.breakpoints.up('lg')]: {
        maxWidth: '50%',
        flex: '0 0 50%',
      },
    },
    newHeaderWrapper: {
      position: 'relative',
    },
    newHeaderSpace: {
      height: 72,
      [theme.breakpoints.up('lg')]: {
        height: 91,
      },
    },
    newHeader: {
      position: 'fixed',
      left: 0,
      right: 0,
      top: 0,
      zIndex: 101,
      background: theme.custom.lightestGray,
      padding: '0 24px',
      borderBottom: `1px solid ${theme.custom.lightestGray}`,
    },
    newHeaderInner: {
      position: 'relative',
      height: 71,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingLeft: 40,
      paddingRight: 40,
      [theme.breakpoints.up('lg')]: {
        height: 90,
        justifyContent: 'flex-start',
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    newHeaderLogoWrapper: {
      padding: 0,
    },
    newHeaderLogo: {
      display: 'flex',
      width: 77,
      height: 40,
    },
    newHeaderToggle: {
      position: 'absolute',
      left: 0,
      top: '50%',
      transform: 'translateY(-50%)',
      display: 'block',
      width: 24,
      height: 21,
      minWidth: 0,
      padding: 0,
      border: 'none',
      background: 'none',
      borderRadius: 0,
      '& .sr-only': {
        position: 'absolute',
        width: 1,
        height: 1,
        padding: 0,
        margin: -1,
        overflow: 'hidden',
        clip: 'rect(0,0,0,0)',
        border: 0,
      },
      '& .icon-bar': {
        display: 'block',
        width: '100%',
        height: 3,
        background: theme.custom.slate,
        position: 'absolute',
        left: 0,
        transition: 'all .25s ease',
        '&:nth-child(2)': {
          top: 0,
        },
        '&:nth-child(3)': {
          top: 9,
        },
        '&:nth-child(4)': {
          top: 18,
        },
      },
      '&.active': {
        '& .icon-bar': {
          background: '#6D6C6B',
          height: 2,
          '&:nth-child(2)': {
            top: 9,
            transform: 'rotate(45deg)',
          },
          '&:nth-child(3)': {
            width: 0,
            opacity: 0,
          },
          '&:nth-child(4)': {
            top: 9,
            transform: 'rotate(-45deg)',
          },
        },
      },
      [theme.breakpoints.up('lg')]: {
        display: 'none',
      },
    },
    newHeaderMobileNavbar: {
      position: 'fixed',
      width: '100%',
      left: 0,
      top: 72,
      height: 'calc(100% - 72px)',
      background: theme.custom.white,
      overflow: 'scroll',
      opacity: 0,
      pointerEvents: 'none',
      backfaceVisibility: 'hidden',
      transition: 'all .3s ease',
      '&.open': {
        opacity: 1,
        pointerEvents: 'auto',
      },
      [theme.breakpoints.up('lg')]: {
        display: 'none',
      },
    },
    newHeaderMobileNav: {
      display: 'flex',
      flexDirection: 'column',
    },
    newHeaderNav: {
      display: 'none',
      marginLeft: 32,
      [theme.breakpoints.up('lg')]: {
        display: 'flex',
      },
    },
    newHeaderMenuItem: {
      display: 'block',
      padding: '10px 0',
      color: theme.custom.slate,
      textDecoration: 'none',
      fontSize: 14,
      lineHeight: 1.57143,
      fontWeight: 700,
      margin: '0 36px 10px',
      borderBottom: '1px solid rgba(0, 0, 0, .25)',
      [theme.breakpoints.up('lg')]: {
        padding: 0,
        margin: '0 0 0 24px',
        color: theme.custom.slate,
        fontWeight: 400,
        borderBottom: 'none',
        '&:hover': {
          fontWeight: 700,
        },
      },
    },
    newHeaderDesktopUserMenu: {
      marginLeft: 'auto',
      display: 'none',
      [theme.breakpoints.up('lg')]: {
        display: 'block',
      },
    },
    newHeaderDesktopUserMenuBtn: {
      padding: 0,
      minWidth: 0,
    },
    newHeaderDesktopUserMenuIcon: {
      fontSize: 20,
      width: 40,
      height: 40,
      verticalAlign: 'top',
      borderRadius: '50%',
      padding: 2,
      backgroundColor: theme.custom.slate,
      color: theme.custom.white,
    },
    newHeaderDesktopUserMenuPaper: {
      maxHeight: 'none',
      maxWidth: 'none',
      overflow: 'visible',
      marginTop: 17,
      marginLeft: 14,
      borderRadius: 0,
      boxShadow: 'none',
      background: 'none',
    },
    newHeaderDesktopUserMenuList: {
      maxWidth: 421,
      width: 421,
      padding: 0,
    },
    newHeaderUserMenu: {
      boxShadow: '0 4px 6px -2px rgba(16, 24, 40, 0.03), 0 12px 16px -4px rgba(16, 24, 40, 0.08)',
      borderRadius: '0 0 10px 10px',
      overflow: 'hidden',
      marginBottom: 40,
      [theme.breakpoints.up('lg')]: {
        borderRadius: 10,
        marginBottom: 0,
        '&::before': {
          content: '""',
          position: 'absolute',
          top: -10,
          right: 22,
          width: 0,
          height: 0,
          borderStyle: 'solid',
          borderWidth: '0 10px 10px 10px',
          borderColor: 'transparent',
          borderBottomColor: theme.custom.slate,
        },
      },
    },
    newHeaderUserMenuTop: {
      background: theme.custom.slate,
      color: theme.custom.white,
      padding: 24,
      '& a': {
        color: theme.custom.white,
        textDecoration: 'underline',
      },
    },
    newHeaderUserMenuUsername: {
      fontSize: 24,
      lineHeight: 1.29167,
      marginBottom: 16,
      '& .icon': {
        fontSize: 16,
      },
    },
    newHeaderUserMenuInfo: {
      fontSize: 14,
      lineHeight: 1.57143,
      marginBottom: 10,
      '&:last-child': {
        marginBottom: 0,
      },
      '& b': {
        display: 'inline-block',
        textTransform: 'uppercase',
        minWidth: 190,
        paddingRight: 8,
      },
    },
    newHeaderUserMenuBottom: {
      background: theme.custom.white,
      color: theme.custom.blue,
      padding: 24,
    },
    newHeaderUserMenuItems: {
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      [theme.breakpoints.up('lg')]: {
        display: 'block',
      },
    },
    newHeaderUserMenuItem: {
      position: 'relative',
      padding: '0 0 0 32px',
      display: 'block',
      marginBottom: 24,
      minHeight: 0,
      fontSize: 16,
      lineHeight: 1.5625,
      color: theme.custom.slate,
      '&:last-child': {
        marginBottom: 0,
      },
      '&:hover': {
        background: 'none',
      },
      '& .icon': {
        fontSize: 16,
        position: 'absolute',
        left: 0,
        top: 5,
      },
      [theme.breakpoints.up('lg')]: {
        marginBottom: 10,
      },
    },
    pageContentWrapper: {
      position: 'relative',
      overflowX: 'hidden',
      padding: '56px 0',
      [theme.breakpoints.up('lg')]: {
        padding: '76px 0',
      },
    },
    topHelperBtn: {
      fontSize: 0,
      lineHeight: 0,
      color: 'transparent',
      fontWeight: 400,
      textDecoration: 'none',
      letterSpacing: 0,
      minWidth: 0,
      padding: 2,
      backgroundColor: alpha(String(theme.custom.white), 0.6),
      borderRadius: 2,
      position: 'fixed',
      zIndex: 100,
      top: 85,
      '&:hover': {
        backgroundColor: alpha(String(theme.custom.white), 0.6),
      },
      '& .icon': {
        fontSize: 24,
        color: theme.custom.darkestGray,
        margin: 0,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 18,
        lineHeight: 1.61112,
        padding: 4,
        color: theme.custom.darkestGray,
        top: 117,
        '& .icon': {
          marginRight: 5,
        },
      },
    },
    exit: {
      right: 13,
      [theme.breakpoints.up('lg')]: {
        right: 46,
      },
    },
    help: {
      top: 'auto',
      bottom: 13,
      right: 13,
      [theme.breakpoints.up('lg')]: {
        bottom: 6,
        right: 46,
      },
    },
    back: {
      left: 13,
      [theme.breakpoints.up('lg')]: {
        left: 46,
      },
    },
    pageContent: {
      position: 'relative',
      margin: '0 auto',
      padding: '0 15px',
      [theme.breakpoints.up('md')]: {
        maxWidth: 718, // 688 + 30
      },
      [theme.breakpoints.up('lg')]: {
        maxWidth: 1140, // 1110 + 30
      },
    },
    pageTitle: {
      textAlign: 'center',
    },
    stepper: {
      maxWidth: 600,
      padding: 0,
      margin: theme.spacing(3, 'auto', 2),
      justifyContent: 'center',
      [theme.breakpoints.up('lg')]: {
        margin: theme.spacing(6, 'auto', 8),
      },
    },
    mobileStepper: {
      textAlign: 'center',
      color: theme.custom.slate,
      fontWeight: 700,
      marginBottom: 24,
      [theme.breakpoints.up('lg')]: {
        display: 'none',
      },
    },
    box: {
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: custom.border,
      borderRadius: 4,
      padding: 15,
      [theme.breakpoints.up('lg')]: {
        padding: 25,
      },
    },
    textCenter: {
      textAlign: 'center',
    },
    fieldWrapper: {
      marginBottom: 24,
    },
    fieldLabel: {
      marginBottom: 8,
      display: 'block',
      fontFamily: theme.custom.akkuratMono,
      textTransform: 'uppercase',
      fontSize: 12,
      fontWeight: 400,
      lineHeight: 1.5834,
      letterSpacing: '0.16em',
      '& span': {
        fontSize: 10,
        letterSpacing: '0.1em',
      },
    },
    fieldTextNoBorder: {
      '& .outlinedInputRoot': {
        background: theme.custom.white,
      },
      '& .outlinedInputNotchedOutline': {
        border: 'none',
      },
    },
    actionsWrapper: {
      textAlign: 'center',
      '& button': {
        width: '100%',
      },
      [theme.breakpoints.up('lg')]: {
        '& button': {
          width: 'auto',
          minWidth: 380,
        },
      },
    },
    actionsError: {
      color: theme.custom.errorRed,
      marginBottom: 16,
    },
    divider: {
      margin: '12px 0',
      height: 1,
      background: custom.border,
    },
    promotion: {
      marginBottom: 30,
      [theme.breakpoints.up('lg')]: {
        marginBottom: 66,
        display: 'flex',
      },
    },
    promotionBanner: {
      display: 'none',
      position: 'relative',
      padding: 40,
      backgroundImage: `url(${withPathPrefix('/images/promo.jpg')})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      '&::before': {
        content: '""',
        position: 'absolute',
        zIndex: 1,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: 'rgba(255,255,255,0.5)',
      },
      [theme.breakpoints.up('lg')]: {
        display: 'block',
        flexGrow: 1,
        borderRadius: '2px 0 0 2px',
      },
    },
    promotionBannerTitle: {
      fontSize: 48,
      lineHeight: 1.2,
      letterSpacing: '-0.05em',
      marginBottom: 10,
      position: 'relative',
      zIndex: 2,
      [theme.breakpoints.up('lg')]: {
        maxWidth: '65%',
      },
    },
    promotionBannerBody: {
      position: 'relative',
      zIndex: 2,
      [theme.breakpoints.up('lg')]: {
        maxWidth: '65%',
      },
    },
    promotionFee: {
      textDecoration: 'line-through',
    },
    promotionBannerCustomImage: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    promotionInfo: {
      position: 'relative',
      color: custom.white,
      borderRadius: 4,
      backgroundColor: custom.slate,
      padding: '60px 15px 40px 15px',
      [theme.breakpoints.up('lg')]: {
        width: 350,
        flexShrink: 0,
        borderRadius: '0 2px 2px 0',
        padding: '90px 15px 40px 40px',
      },
    },
    promotionInfoLabel: {
      position: 'absolute',
      right: 0,
      top: 40,
      backgroundColor: custom.gold,
      color: custom.black,
      display: 'flex',
      alignItems: 'center',
      fontFamily: theme.custom.akkuratMono,
      fontSize: 10,
      lineHeight: '22px',
      textTransform: 'uppercase',
      letterSpacing: '0.16em',
      padding: '0 20px 0 10px',
      fontWeight: 400,
      '& .icon': {
        marginRight: 5,
        fontSize: 14,
      },
    },
    promotionInfoPeriod: {
      fontSize: 18,
      fontWeight: 700,
      marginBottom: 20,
      '& span': {
        display: 'block',
        fontSize: 16,
        fontWeight: 400,
      },
      [theme.breakpoints.up('lg')]: {
        marginBottom: 30,
      },
    },
    promotionInfoPriceOld: {
      fontSize: 18,
      lineHeight: 1.1,
      fontWeight: 700,
      opacity: 0.72,
      marginBottom: 8,
      textDecoration: 'line-through',
    },
    promotionInfoPrice: {
      letterSpacing: '-0.05em',
      fontWeight: 400,
      fontSize: 48,
      lineHeight: 1.2,
      marginBottom: 20,
      '& span': {
        fontSize: 18,
        fontWeight: 700,
        opacity: 0.6,
        letterSpacing: 0,
      },
      [theme.breakpoints.up('lg')]: {
        marginBottom: 30,
        fontSize: 80,
        lineHeight: 1,
        fontWeight: 300,
        '& span': {
          fontSize: 18,
        },
      },
    },
    promotionInfoDescription: {
      marginBottom: 20,
      '& del': {
        opacity: 0.72,
      },
      [theme.breakpoints.up('lg')]: {
        marginBottom: 50,
      },
    },
    promotionInfoButton: {},
    plansOther: {
      marginBottom: 34,
      [theme.breakpoints.up('lg')]: {
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'stretch',
        marginBottom: 58,
        '& > *': {
          width: '100%',
        },
        '& > * + *': {
          marginLeft: 30,
        },
      },
    },
    familyMembers: {
      backgroundColor: theme.custom.lightestGray,
      borderColor: theme.custom.lightestGray,
      marginBottom: 30,
      [theme.breakpoints.up('lg')]: {
        marginBottom: 0,
      },
    },
    familyMembersDescription: {
      marginBottom: 24,
      '& span': {
        display: 'block',
        fontSize: 14,
        color: theme.custom.lightText,
        marginTop: 14,
      },
    },
    familyMembersSubTitle: {
      marginBottom: 24,
    },
    familyMembersBtn: {
      backgroundColor: theme.custom.lightestGray,
    },
    travelProtection: {
      backgroundColor: theme.custom.lightestGray,
      borderColor: theme.custom.lightestGray,
      marginBottom: 30,
      '&.error': {
        borderColor: theme.custom.errorRed,
      },
      '&.disabled': {
        opacity: 0.5,
        position: 'relative',
        '&::after': {
          content: '""',
          display: 'block',
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          zIndex: 2,
        },
      },
      '& .strikethrough': {
        color: custom.orange,
        fontWeight: '400',
        fontSize: 12,
        textDecoration: 'line-through',
      },
      [theme.breakpoints.up('lg')]: {
        marginBottom: 0,
      },
    },
    travelProtectionDescription: {
      marginBottom: 10,
      '& ul': {
        margin: 0,
        padding: '0 0 0 15px',
      },
      '& li': {
        marginTop: 8,
        '&:first-child': {
          marginTop: 0,
        },
      },
      '& span': {
        display: 'block',
        fontSize: 14,
        lineHeight: 1.7143,
        color: theme.custom.lightText,
        marginTop: 8,
      },
    },
    travelProtectionRadios: {
      padding: 0,
    },
    travelProtectionError: {
      color: theme.custom.errorRed,
      marginTop: 10,
    },
    travelProtectionBtn: {
      marginTop: 10,
    },
    travelProtectionRadioWrapper: {
      marginRight: 0,
    },
    travelProtectionRadio: {
      alignSelf: 'flex-start',
    },
    travelProtectionRadioLabel: {
      flexGrow: 1,
    },
    travelProtectionRadioEditWrapper: {
      padding: 0,
    },
    travelProtectionRadioEdit: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    travelProtectionRadioEditText: {
      flexGrow: 1,
    },
    travelProtectionRadioEditBtn: {
      flexShrink: 0,
      marginLeft: 10,
      lineHeight: 1.5,
      fontSize: 16,
      '& .icon': {
        fontSize: 20,
        marginRight: 5,
      },
    },
    preselectTravelBlock: {
      margin: '20px 0',
    },
    reviewDetails: {
      maxWidth: 730,
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: 30,
      [theme.breakpoints.up('lg')]: {
        marginBottom: 60,
      },
    },
    reviewDetailsMember: {
      marginBottom: 24,
    },
    reviewDetail: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      margin: '12px 0',
      '& span': {
        fontSize: 14,
        lineHeight: 1.715,
        color: theme.custom.darkGray,
      },
    },
    reviewDetailPromo: {
      background: 'rgba(46, 212, 122, 0.12)',
    },
    reviewDetailFirst: {
      marginBottom: 24,
    },
    reviewDetailsSubscriptionWrapper: {
      fontSize: 12,
      lineHeight: 1.3334,
      color: theme.custom.darkGray,
      paddingTop: 24,
      marginTop: 24,
      marginBottom: 24,
      borderTopStyle: 'solid',
      borderTopWidth: 1,
      borderTopColor: custom.border,
      [theme.breakpoints.up('lg')]: {
        paddingTop: 0,
        borderTopColor: 'transparent',
      },
    },
    reviewDetailsSubscription: {
      position: 'relative',
      paddingLeft: 24,
      cursor: 'pointer',
      '& .icon': {
        fontSize: 16,
        position: 'absolute',
        left: 0,
        top: 0,
        color: theme.custom.darkGray,
      },
    },
    reviewDetailsDonate: {
      margin: '24px 0',
    },
    reviewDonateRadios: {
      marginBottom: 12,
    },
    reviewDonateAmount: {
      maxWidth: 375,
    },
    yourTotalToday: {
      marginTop: 24,
      marginBottom: 12,
      color: theme.custom.darkGray,
      '& h3': {
        color: 'inherit',
        marginBottom: 0,
      },
    },
    yourTotalTodayPrice: {
      fontWeight: 700,
      'h3&': {
        color: theme.custom.slate,
      },
    },
    reviewTerms: {
      marginBottom: 30,
      '& p': {
        width: '100%',
      },
      [theme.breakpoints.up('lg')]: {
        justifyContent: 'center',
        maxWidth: 550,
        marginLeft: 'auto',
        marginRight: 'auto',
        '& p': {
          textAlign: 'center',
        },
      },
    },
    completeInformation: {
      maxWidth: 730,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    completeInformationBlock: {
      marginBottom: 37,
      [theme.breakpoints.up('lg')]: {
        marginBottom: 53,
      },
    },
    completeInformationRadios: {
      marginBottom: 12,
    },
    completeInformationMethods: {
      padding: 0,
    },
    completeInformationMethod: {
      marginBottom: 16,
      borderRadius: 3,
      '&:last-child': {
        marginBottom: 0,
      },
      '&.active': {
        background: theme.custom.lightestGray,
      },
      '&.item-1': {
        '& $completeInformationMethodContent': {
          color: theme.custom.darkGray,
          fontSize: 14,
          padding: '0 40px 16px 56px',
          marginTop: -8,
        },
      },
    },
    completeInformationMethodBtn: {
      borderRadius: 3,
      alignItems: 'center',
      justifyContent: 'flex-start',
      width: '100%',
      position: 'relative',
      padding: '16px 16px 16px 56px',
      fontSize: 15,
      lineHeight: '40px',
      textDecoration: 'none',
      fontWeight: 400,
      '& .mark': {
        position: 'absolute',
        left: 16,
        top: 24,
      },
      '& .icon': {
        fontSize: 24,
        marginRight: 4,
      },
      '& .applePayIcon': {
        fontSize: 40,
        width: 63,
      },
      '& .googlePayIcon': {
        fontSize: 40,
        width: 76,
      },
    },
    completeInformationMethodContent: {
      padding: '24px 40px 16px',
    },
    thankYouReceipt: {
      textAlign: 'center',
      marginTop: 17,
    },
    thankYouReceiptHHM: {
      textAlign: 'center',
      color: theme.custom.darkGray,
      marginTop: 16,
    },
    thankYouCardWrapper: {
      backgroundImage: `url(${withPathPrefix('/images/thankyou.jpg')})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      padding: '24px',
      margin: '24px -15px',
      [theme.breakpoints.up('md')]: {
        margin: '24px 0',
        borderRadius: 8,
      },
      [theme.breakpoints.up('lg')]: {
        padding: '48px',
        margin: '48px 0',
      },
    },
    checkoutWesaluteCardImg: {
      position: 'relative',
      width: 250,
      maxWidth: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: 24,
      '& img': {
        display: 'block',
        width: '100%',
        maxWidth: '100%',
        height: 'auto',
      },
      [theme.breakpoints.up('lg')]: {
        width: 284,
      },
    },
    checkoutWesaluteCardName: {
      color: '#053A4E',
      position: 'absolute',
      left: '7%',
      right: '7%',
      bottom: 50,
      fontSize: 5,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      '& > span': {
        fontSize: 12,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 13,
        lineHeight: 1.3,
        '& > span': {
          fontSize: 13,
        },
      },
    },
    checkoutWesaluteCardId: {
      color: '#053A4E',
      lineHeight: 1.3,
      position: 'absolute',
      left: '7%',
      bottom: 25,
      textTransform: 'uppercase',
      fontSize: 5,
      '& > span': {
        color: theme.custom.darkGray,
        fontWeight: 700,
        fontSize: 5,
      },
      [theme.breakpoints.up('lg')]: {
        bottom: 18,
        fontSize: 10,
        '& > span': {
          fontSize: 6,
        },
      },
    },
    checkoutWesaluteCardDate: {
      color: '#053A4E',
      textAlign: 'right',
      lineHeight: 1.3,
      position: 'absolute',
      right: '7%',
      bottom: 25,
      textTransform: 'uppercase',
      fontSize: 7,
      '& > span': {
        color: '#617891',
        fontWeight: 700,
        fontSize: 5,
      },
      [theme.breakpoints.up('lg')]: {
        bottom: 18,
        fontSize: 9,
        '& > span': {
          fontSize: 6,
        },
      },
    },
    thankYouBenefits: {
      background: theme.custom.white,
      boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1)',
      borderRadius: '4px 4px 0 0',
      maxWidth: 480,
      margin: '0 auto',
      [theme.breakpoints.up('md')]: {
        maxWidth: 671,
      },
    },
    thankYouBenefitsTitle: {
      background: theme.custom.lightMint,
      textAlign: 'center',
      lineHeight: 1.625,
      fontWeight: 700,
      padding: 8,
      borderRadius: '4px 4px 0 0',
      [theme.breakpoints.up('md')]: {
        padding: 10,
      },
    },
    thankYouBenefitsListWrapper: {
      padding: '24px 16px 0',
      maxWidth: 287,
      margin: '0 auto',
      [theme.breakpoints.up('md')]: {
        padding: '24px 46px 0',
        maxWidth: 'none',
      },
    },
    thankYouBenefitsList: {
      padding: 0,
      margin: 0,
      listStyle: 'none',
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'flex-start',
      marginLeft: -12,
      marginRight: -12,
      '& li': {
        position: 'relative',
        width: '100%',
        paddingLeft: 12,
        paddingRight: 12,
        fontSize: 12,
        lineHeight: 1.58334,
        display: 'flex',
        alignItems: 'center',
        marginBottom: 24,
      },
      '& .icon': {
        fontSize: 32,
        background: theme.custom.lightMint,
        borderRadius: 2,
        flexShrink: 0,
        marginRight: 16,
        alignSelf: 'flex-start',
      },
      [theme.breakpoints.up('md')]: {
        '& li': {
          fontSize: 14,
          lineHeight: 1.57143,
          flex: '0 0 50%',
          maxWidth: '50%',
        },
        '& .icon': {
          fontSize: 40,
        },
      },
    },
    thankYouBrands: {
      marginBottom: 24,
    },
    thankYouBrandsTitle: {
      textAlign: 'center',
      fontSize: 24,
      lineHeight: 1.29167,
      letterSpacing: '-0.02em',
      marginBottom: 24,
    },
    thankYouBrandsListWrapper: {
      maxWidth: 280,
      margin: '0 auto',
      [theme.breakpoints.up('md')]: {
        maxWidth: 'none',
      },
      [theme.breakpoints.up('lg')]: {
        maxWidth: 686,
      },
    },
    thankYouBrandsList: {
      padding: 0,
      margin: 0,
      listStyle: 'none',
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'flex-start',
      marginLeft: -12,
      marginRight: -12,
      '& li': {
        position: 'relative',
        width: '100%',
        paddingLeft: 12,
        paddingRight: 12,
        display: 'block',
        marginBottom: 24,
        flex: '0 0 33.333333%',
        maxWidth: '33.333333%',
        [theme.breakpoints.up('md')]: {
          flex: '0 0 16.666667%',
          maxWidth: '16.666667%',
        },
      },
      '& a': {
        display: 'block',
      },
      '& img': {
        display: 'block',
        width: '100%',
        maxWidth: '100%',
        height: 'auto',
        borderRadius: 8,
        boxShadow: '0 1px 2px 0 rgba(16, 24, 40, 0.06), 0 1px 3px 0 rgba(16, 24, 40, 0.1)',
      },
    },
    thankYouCardBtns: {
      textAlign: 'center',
      margin: '0 auto',
      maxWidth: 280,
    },
    thankYouCardBtnGold: {
      width: '100%',
      backgroundColor: theme.custom.gold,
      color: theme.custom.slate,
      marginBottom: 16,
      '&:hover': {
        color: theme.custom.white,
        backgroundColor: theme.custom.slate,
      },
    },
    thankYouCardBtn: {
      width: '100%',
      alignItems: 'flex-start',
      marginBottom: 16,
      '& .icon': {
        fontSize: 16,
        marginTop: 4,
        marginRight: 8,
      },
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    dialog: {
      width: '100%',
      maxWidth: 830,
      margin: 0,
      borderRadius: 0,
      [theme.breakpoints.up('lg')]: {
        margin: '30px 0',
      },
    },
    dialogTitle: {
      margin: 0,
      flex: '0 0 auto',
      position: 'relative',
      padding: '16px 50px 16px 20px',
      backgroundColor: theme.custom.lightGrayDark,
      [theme.breakpoints.up('lg')]: {
        padding: '16px 60px 16px 50px',
      },
    },
    dialogSubTitle: {
      color: 'inherit',
      marginBottom: 16,
      [theme.breakpoints.up('lg')]: {
        marginBottom: 24,
      },
    },
    dialogTitleClose: {
      padding: 12,
      color: theme.custom.darkGray,
      position: 'absolute',
      right: 0,
      top: 7,
      [theme.breakpoints.up('lg')]: {
        right: 10,
        top: 17,
      },
    },
    dialogContent: {
      padding: '20px',
      [theme.breakpoints.up('lg')]: {
        padding: '50px 50px 20px',
      },
    },
    dialogActions: {
      justifyContent: 'center',
      flexWrap: 'wrap',
      padding: '0 20px 20px',
      [theme.breakpoints.up('lg')]: {
        padding: '0 50px 50px',
      },
    },
    addTravelProtectionDialog: {
      maxWidth: 830,
      '& $dialogContent': {
        [theme.breakpoints.up('lg')]: {
          paddingBottom: 50,
        },
      },
      '& $accountDialogAddTravelProtectionTotal': {
        color: theme.custom.slate,
        '& span': {
          color: theme.custom.slate,
        },
      },
      '& $dialogActions': {
        textAlign: 'center',
        padding: '0px 30px 30px',
        '& button': {
          width: '100%',
        },
        [theme.breakpoints.up('lg')]: {
          padding: '50px 0;',
          '& button': {
            width: 'auto',
            marginLeft: 'auto',
            marginRight: 'auto',
            minWidth: 280,
            display: 'flex',
          },
        },
      },
    },
    addTravelProtectionDialogHouseholdDescription: {
      paddingLeft: 28,
      '& span': {
        color: theme.custom.darkGray,
        display: 'block',
        fontSize: 14,
        marginBottom: 5,
      },
    },
    travelProtectionDialog: {
      maxWidth: 850,
    },
    travelProtectionDialogText: {
      color: theme.custom.darkGray,
      '& ul': {
        margin: 0,
        padding: 0,
        listStyle: 'none',
      },
      '& li': {
        position: 'relative',
        paddingLeft: 34,
        marginTop: 23,
        '&:first-child': {
          marginTop: 0,
        },
        '&::before': {
          content: '""',
          display: 'block',
          width: 13,
          height: 7,
          borderStyle: 'solid',
          borderWidth: 0,
          borderColor: 'currentColor',
          borderLeftWidth: 2,
          borderBottomWidth: 2,
          transform: 'rotate(-45deg)',
          position: 'absolute',
          left: 0,
          top: 5,
        },
      },
    },
    trialDialog: {
      maxWidth: 872,
      '& $dialogActions': {
        [theme.breakpoints.up('lg')]: {
          justifyContent: 'flex-start',
        },
        '& button': {
          display: 'block',
          width: '100%',
          [theme.breakpoints.up('lg')]: {
            display: 'inline-flex',
            width: 'auto',
          },
        },
        '& button + button': {
          marginTop: 24,
          [theme.breakpoints.up('lg')]: {
            marginTop: 0,
            marginLeft: 24,
          },
        },
      },
    },
    trialDialogMainGraph: {
      position: 'relative',
      zIndex: 0,
      maxWidth: 384,
      marginBottom: 24,
    },
    trialDialogMainGraphTop: {
      position: 'relative',
      '&::before': {
        content: '""',
        display: 'block',
        background: theme.custom.slate,
        borderRadius: 14,
        position: 'absolute',
        width: 28,
        left: 0,
        top: 0,
        bottom: 0,
      },
    },
    trialDialogMainGraphBottom: {
      paddingBottom: 33,
      position: 'relative',
      '&::before': {
        content: '""',
        display: 'block',
        background: `linear-gradient(180deg, ${theme.custom.mint} 50%, ${alpha(String(theme.custom.mint), 0)} 100%)`,
        borderRadius: 1,
        position: 'absolute',
        width: 28,
        left: 0,
        top: -15,
        bottom: 0,
        zIndex: -1,
      },
    },
    trialDialogMainGraphTitle: {
      position: 'relative',
      paddingLeft: 38,
      fontSize: 18,
      fontWeight: 700,
      lineHeight: 1.61112,
      marginTop: 22,
      '&:first-child': {
        marginTop: 0,
      },
      '& .icon': {
        fontSize: 16,
        position: 'absolute',
        left: 6,
        top: 6,
      },
    },
    trialDialogMainGraphDescription: {
      position: 'relative',
      paddingLeft: 38,
      fontSize: 14,
    },
    trialDialogInfo: {
      marginBottom: 12,
    },
    trialDialogInfoTitle: {
      fontSize: 16,
      fontWeight: 700,
      paddingBottom: 12,
    },
    trialDialogInfoSubTitle: {
      fontSize: 24,
      lineHeight: 1.33334,
      letterSpacing: '-0.02em',
      paddingBottom: 12,
    },
    trialDialogInfoDescription: {
      fontSize: 14,
    },
    donateDialog: {
      maxWidth: 920,
    },
    donateBanner: {
      position: 'relative',
      overflow: 'hidden',
      background: custom.white,
      borderRadius: 8,
      boxShadow: '2px 8px 16px rgba(0, 0, 0, 0.12)',
      marginTop: 40,
      marginBottom: 20,
      [theme.breakpoints.up('lg')]: {
        display: 'flex',
      },
    },
    donateBannerImage: {
      height: 384,
      backgroundImage: `url(${withPathPrefix('/images/donate.jpg')})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      [theme.breakpoints.up('lg')]: {
        minWidth: 255,
        height: 'auto',
      },
    },
    donateBannerInfo: {
      padding: '20px 15px',
      [theme.breakpoints.up('lg')]: {
        flexGrow: 1,
        padding: '50px 60px 50px 30px',
      },
    },
    donateBannerInfoQuote: {
      fontSize: 22,
      lineHeight: 1.36,
      marginBottom: 30,
    },
    membersDialog: {
      maxWidth: 850,
      '& $dialogActions': {
        '& button': {
          display: 'block',
          width: '100%',
          [theme.breakpoints.up('lg')]: {
            display: 'inline-flex',
            width: 200,
          },
        },
        '& button + button': {
          marginTop: 30,
          [theme.breakpoints.up('lg')]: {
            marginTop: 0,
            marginLeft: 30,
          },
        },
      },
    },
    membersDialogActionsRow: {
      width: '100%',
      marginTop: 30,
      [theme.breakpoints.up('lg')]: {
        textAlign: 'center',
        marginTop: 48,
      },
    },
    membersDialogActionsRowBtn: {
      fontSize: 16,
      padding: 0,
    },
    membersDialogDescription: {
      paddingBottom: 24,
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: theme.custom.border,
      '& span': {
        display: 'block',
        fontSize: 14,
        color: theme.custom.lightText,
        marginTop: 10,
      },
    },
    membersDialogShowMore: {
      marginTop: 24,
    },
    membersDialogAdded: {
      paddingTop: 24,
      paddingBottom: 24,
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: theme.custom.border,
    },
    membersDialogAddedTitle: {
      fontSize: 18,
      fontWeight: 400,
      lineHeight: 1.5556,
      marginBottom: 15,
    },
    membersDialogAddedItem: {
      position: 'relative',
      fontSize: 14,
      marginBottom: 10,
      '&:last-child': {
        marginBottom: 0,
      },
      [theme.breakpoints.up('lg')]: {
        display: 'flex',
        flexWrap: 'nowrap',
        paddingRight: 20,
      },
    },
    membersDialogAddedClose: {
      position: 'absolute',
      right: 0,
      bottom: 2,
      padding: 0,
      color: custom.orange,
      '& .icon': {
        fontSize: 20,
      },
      [theme.breakpoints.up('lg')]: {
        top: 1,
        bottom: 'auto',
      },
    },
    membersDialogAddedName: {
      fontWeight: 700,
      [theme.breakpoints.up('lg')]: {
        width: '20%',
        paddingRight: 10,
      },
    },
    membersDialogAddedRelationship: {
      textTransform: 'capitalize',
      [theme.breakpoints.up('lg')]: {
        width: '20%',
        paddingRight: 10,
      },
    },
    membersDialogAddedEmail: {
      wordBreak: 'break-all',
      [theme.breakpoints.up('lg')]: {
        width: '40%',
        paddingRight: 10,
      },
    },
    membersOldPrice: {
      '& span': {
        color: custom.orange,
        fontSize: 'inherit',
        fontWeight: 400,
        marginRight: 5,
        textDecoration: 'line-through',
      },
    },
    membersDialogAddedPrice: {
      paddingRight: 30,
      '& span': {
        color: custom.orange,
        textDecoration: 'line-through',
      },
      [theme.breakpoints.up('lg')]: {
        width: '20%',
        paddingRight: 10,
      },
    },
    membersDialogForm: {
      paddingTop: 24,
    },
    membersDialogAddButton: {
      marginTop: 24,
    },
    cardElement: {
      padding: '16px 14px',
      height: 50,
      fontFamily: typography.fontFamily,
      fontWeight: 400,
      fontSize: '16px',
      borderRadius: 2,
      border: '1px solid',
      borderColor: custom.border,
    },
    loadingBlock: {
      minHeight: '175px',
      width: '100%',
      height: '100%',
      background: 'linear-gradient(270deg, #bbbbbb, #e2e1e1)',
      backgroundSize: '400% 400%',
      animation: '$loading-placeholders 2s ease-in-out infinite',
      borderRadius: 6,
    },
    '@keyframes loading-placeholders': {
      '0%': {
        backgroundPosition: '0 50%',
      },
      '50%': {
        backgroundPosition: '100% 50%',
      },
      '100%': {
        backgroundPosition: '0 50%',
      },
    },
    loader: {
      position: 'fixed',
      top: 74,
      left: 0,
      right: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      textAlign: 'center',
    },
    '@media print': {
      noPrint: {
        display: 'none',
      },
    },
    // OTP styles.
    pageOtpSubTitle: {
      textAlign: 'center',
      fontWeight: 700,
      margin: theme.spacing(2, 0, 3),
      [theme.breakpoints.up('lg')]: {
        fontSize: 24,
        lineHeight: 1.25,
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(6),
      },
    },
    pageOtpPlanFilters: {
      textAlign: 'center',
      marginTop: 48,
      [theme.breakpoints.up('lg')]: {
        marginTop: 64,
      },
    },
    pageOtpPlanFiltersBtns: {
      padding: 0,
    },
    pageOtpPlanFiltersBtn: {
      width: 180,
      '&.left': {
        borderRadius: '2px 0 0 2px',
      },
      '&.right': {
        borderRadius: '0 2px 2px 0',
      },
    },
    pageOtpPlanFiltersBtnCompare: {
      marginTop: 18,
      fontWeight: 400,
      '&.active': {
        fontWeight: 700,
      },
    },
    pageOtpCards: {
      marginTop: 30,
      [theme.breakpoints.up('lg')]: {
        marginTop: 110,
        paddingBottom: 90,
      },
    },
    pageOtpCardsOtp: {
      [theme.breakpoints.up('lg')]: {
        paddingBottom: 48,
        '& $pageOtpCardActive $pageOtpCardBenefits': {
          display: 'none',
        },
        '& $pageOtpCard:hover $pageOtpCardBenefits': {
          display: 'none',
        },
      },
    },
    pageOtpCardsOtpBenefits: {
      display: 'none',
      [theme.breakpoints.up('lg')]: {
        display: 'block',
        textAlign: 'center',
        fontSize: 12,
        paddingTop: 41,
      },
    },
    pageOtpCardsSameHeight: {
      [theme.breakpoints.up('lg')]: {
        '& $pageOtpCardInner': {
          paddingTop: 53,
          paddingBottom: 11,
          '&::before': {
            top: -45,
            bottom: -21,
          },
        },
      },
    },
    pageOtpCard: {
      position: 'relative',
      marginBottom: 24,
      [theme.breakpoints.up('lg')]: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        marginBottom: 0,
        '&:hover $pageOtpCardBenefits': {
          display: 'block',
        },
      },
    },
    pageOtpCardInner: {
      padding: '24px 15px',
      position: 'relative',
      '&::before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        borderRadius: 4,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.custom.border,
        // transition: '200ms',
      },
      [theme.breakpoints.up('lg')]: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        padding: '32px 15px',
      },
    },
    pageOtpCardContent: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    pageOtpCardBestValue: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: theme.custom.gold,
      color: theme.custom.slate,
      padding: '0 15px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: 16,
      marginLeft: -15,
      marginRight: -15,
      height: 42,
      marginTop: -66,
      marginBottom: 24,
      borderRadius: '4px 4px 0 0',
      '& .icon': {
        marginRight: 10,
      },
      [theme.breakpoints.up('lg')]: {
        marginTop: -71,
        fontSize: 24,
        height: 66,
        marginBottom: 32,
        justifyContent: 'center',
      },
    },
    pageOtpCardTitle: {
      fontWeight: 700,
      fontSize: 16,
      lineHeight: 1.5,
      paddingRight: 130,
      [theme.breakpoints.up('lg')]: {
        fontSize: 18,
        lineHeight: 1.3889,
        paddingRight: 0,
        textAlign: 'center',
        marginBottom: 24,
      },
    },
    pageOtpCardOldPrice: {
      textDecoration: 'line-through',
      fontSize: 16,
      lineHeight: 1.5,
      color: theme.custom.darkGray,
      paddingRight: 130,
      [theme.breakpoints.up('lg')]: {
        fontSize: 24,
        lineHeight: 1.25,
        paddingRight: 0,
        textAlign: 'center',
        marginBottom: 16,
      },
    },
    pageOtpCardPrice: {
      letterSpacing: '-0.05em',
      fontSize: 48,
      lineHeight: 1.2084,
      paddingRight: 130,
      marginBottom: 14,
      '& span': {
        fontSize: 24,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 88,
        lineHeight: 1,
        letterSpacing: '-0.04em',
        fontWeight: 300,
        paddingRight: 0,
        textAlign: 'center',
        marginBottom: 24,
        '& span': {
          fontSize: 24,
        },
      },
    },
    pageOtpCardNote: {
      fontSize: 14,
      lineHeight: 1.7143,
      '& ul': {
        margin: 0,
        paddingLeft: 20,
        '& span': {
          display: 'block',
          fontSize: 12,
          fontWeight: 300,
          color: theme.custom.lightText,
          marginBottom: 10,
          [theme.breakpoints.up('lg')]: {
            marginTop: 3,
            marginBottom: 0,
          },
        },
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 16,
        lineHeight: 1.5,
        marginBottom: 30,
        '& li': {
          padding: '4px 0',
        },
      },
    },
    pageOtpCardBtn: {
      height: 40,
      fontSize: 14,
      position: 'absolute',
      right: 0,
      top: 0,
      width: 120,
      '&.Mui-disabled': {
        color: theme.custom.slate,
        borderColor: theme.custom.slate,
      },
      [theme.breakpoints.up('lg')]: {
        height: 54,
        fontSize: 16,
        position: 'relative',
        right: 'auto',
        top: 'auto',
        width: 200,
        marginTop: 'auto',
        alignSelf: 'center',
      },
    },
    pageOtpCardBenefits: {
      paddingTop: 10,
      fontSize: 12,
      color: theme.custom.darkGray,
      [theme.breakpoints.up('lg')]: {
        position: 'absolute',
        top: '100%',
        display: 'none',
      },
    },
    pageOtpCardActive: {
      [theme.breakpoints.up('lg')]: {
        '& $pageOtpCardBenefits': {
          display: 'block',
        },
      },
    },
    pageOtpCardFeatured: {
      '& $pageOtpCardInner': {
        paddingTop: 66,
      },
      [theme.breakpoints.up('lg')]: {
        '& $pageOtpCardInner': {
          paddingTop: 26,
          paddingBottom: 11,
          '&::before': {
            top: -45,
            bottom: -21,
          },
        },
        '& $pageOtpCardBtn': {
          marginBottom: 0,
        },
        '& $pageOtpCardBenefits': {
          marginTop: 21,
        },
      },
    },
    pageOtpCardInActive: {
      opacity: 0.5,
      '& $pageOtpCardBenefits': {
        display: 'none',
      },
      '&:hover $pageOtpCardBenefits': {
        display: 'none',
      },
    },
    pageOtpCompareCards: {
      paddingTop: 24,
      [theme.breakpoints.up('lg')]: {
        paddingTop: 64,
        paddingBottom: 93,
      },
    },
    pageOtpCompareCardsGroup: {
      padding: 0,
    },
    pageOtpCompareCardsGroupSubscription: {
      [theme.breakpoints.up('lg')]: {
        marginBottom: 77,
      },
    },
    pageOtpCompareCardsGroupOtp: {
      padding: 0,
    },
    pageOtpCompareCardsGroupHeader: {
      padding: 10,
      backgroundColor: theme.custom.slate,
      color: theme.custom.white,
      marginBottom: 24,
      [theme.breakpoints.up('lg')]: {
        padding: 0,
        backgroundColor: 'transparent',
        color: 'inherit',
        marginBottom: 20,
      },
    },
    pageOtpCompareCardsGroupHeaderTitle: {
      fontSize: 24,
      lineHeight: 1.25,
      [theme.breakpoints.up('lg')]: {
        padding: 0,
      },
    },
    pageOtpCompareCardsGroupHeaderSubTitle: {
      padding: 0,
    },
    pageOtpCompareCardsGroupList: {
      padding: 0,
    },
    pageOtpCompareCard: {
      position: 'relative',
      marginBottom: 24,
      [theme.breakpoints.up('lg')]: {
        marginBottom: 0,
        paddingBottom: 77,
        '&:hover': {
          '& $pageOtpCompareCardBenefits': {
            display: 'block',
          },
        },
        '&:last-child': {
          paddingBottom: 0,
        },
      },
    },
    pageOtpCompareCardContent: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      borderRadius: 4,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.custom.border,
      padding: '23px 14px',
      [theme.breakpoints.up('lg')]: {
        flexDirection: 'row',
        padding: '16px 52px 16px 47px',
      },
    },
    pageOtpCompareCardCol: {
      [theme.breakpoints.up('lg')]: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      },
    },
    pageOtpCompareCardFirstCol: {
      [theme.breakpoints.up('lg')]: {
        width: 310,
        flexShrink: 0,
      },
    },
    pageOtpCompareCardSecondCol: {
      order: 10,
      [theme.breakpoints.up('lg')]: {
        order: 0,
        flexGrow: 1,
        paddingLeft: 47,
        paddingRight: 38,
        borderWidth: 0,
        borderLeftWidth: 1,
        borderRightWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.custom.border,
        marginLeft: 20,
        marginRight: 52,
      },
    },
    pageOtpCompareCardThirdCol: {
      [theme.breakpoints.up('lg')]: {
        width: 210,
        flexShrink: 0,
        textAlign: 'center',
      },
    },
    pageOtpCompareCardBestValue: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: theme.custom.gold,
      color: theme.custom.slate,
      padding: '0 15px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: 16,
      marginLeft: -15,
      marginRight: -15,
      height: 42,
      marginTop: -24,
      marginBottom: 24,
      borderRadius: '4px 4px 0 0',
      '& .icon': {
        marginRight: 10,
      },
      [theme.breakpoints.up('lg')]: {
        height: 'auto',
        marginTop: 0,
        marginLeft: 0,
        marginRight: 0,
        marginBottom: 8,
        borderRadius: 0,
        padding: '5px 10px',
        alignSelf: 'flex-start',
        whiteSpace: 'normal',
      },
    },
    pageOtpCompareCardTitle: {
      fontWeight: 700,
      fontSize: 16,
      lineHeight: 1.5,
      paddingRight: 130,
      [theme.breakpoints.up('lg')]: {
        fontWeight: 400,
        fontSize: 24,
        lineHeight: 1.25,
        paddingRight: 0,
      },
    },
    pageOtpCompareCardNote: {
      fontSize: 14,
      lineHeight: 1.7143,
      '& ul': {
        margin: 0,
        paddingLeft: 20,
        '& span': {
          display: 'block',
          fontSize: 12,
          fontWeight: 300,
          color: theme.custom.lightText,
          marginBottom: 10,
        },
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 16,
        lineHeight: 1.55,
        '& li': {
          padding: '5px 0',
        },
      },
    },
    pageOtpCompareCardOldPrice: {
      textDecoration: 'line-through',
      fontSize: 16,
      lineHeight: 1.5,
      color: theme.custom.darkGray,
      paddingRight: 130,
      [theme.breakpoints.up('lg')]: {
        fontWeight: 400,
        fontSize: 24,
        lineHeight: 1.25,
        paddingRight: 0,
        marginBottom: 10,
        color: theme.custom.lightText,
      },
    },
    pageOtpCompareCardPrice: {
      letterSpacing: '-0.05em',
      fontSize: 48,
      lineHeight: 1.2084,
      paddingRight: 130,
      marginBottom: 14,
      '& span': {
        fontSize: 24,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 32,
        lineHeight: 1.3125,
        paddingRight: 0,
        letterSpacing: '-0.02em',
        marginBottom: 10,
        '& span': {
          fontSize: 'inherit',
        },
      },
    },
    pageOtpCompareCardBtn: {
      height: 40,
      fontSize: 14,
      position: 'absolute',
      right: 14,
      top: 23,
      width: 120,
      '&.Mui-disabled': {
        color: theme.custom.slate,
        borderColor: theme.custom.slate,
      },
      [theme.breakpoints.up('lg')]: {
        height: 54,
        fontSize: 16,
        position: 'relative',
        right: 'auto',
        top: 'auto',
        width: 'auto',
      },
    },
    pageOtpCompareCardBenefits: {
      paddingTop: 10,
      fontSize: 12,
      color: theme.custom.darkGray,
      [theme.breakpoints.up('lg')]: {
        position: 'absolute',
        left: 0,
        right: 0,
        paddingTop: 0,
        paddingLeft: 10,
        display: 'none',
      },
    },
    pageOtpCompareCardActive: {
      '& $pageOtpCompareCardBenefits': {
        [theme.breakpoints.up('lg')]: {
          display: 'block',
        },
      },
    },
    pageOtpCompareCardFeatured: {
      '& $pageOtpCompareCardBtn': {
        marginTop: 42,
        [theme.breakpoints.up('lg')]: {
          marginTop: 0,
        },
      },
    },
    pageOtpCompareCardInActive: {
      opacity: 0.5,
      '& $pageOtpCompareCardBenefits': {
        display: 'none',
      },
      '&:hover $pageOtpCompareCardBenefits': {
        display: 'none',
      },
    },
    pageOtpDivider: {
      display: 'none',
      height: 2,
      backgroundColor: theme.custom.slate,
      [theme.breakpoints.up('lg')]: {
        display: 'block',
        marginBottom: 48,
      },
    },
    pageOtpSelection: {
      backgroundColor: theme.custom.slate,
      color: theme.custom.white,
      padding: '4px 26px',
      marginBottom: 24,
      fontSize: 14,
      [theme.breakpoints.up('lg')]: {
        fontSize: 16,
        lineHeight: 1.5,
        display: 'flex',
        justifyContent: 'space-between',
        borderRadius: 4,
        padding: '25px 40px',
        marginBottom: 58,
      },
    },
    pageOtpSelectionName: {
      [theme.breakpoints.up('lg')]: {
        fontSize: 24,
        lineHeight: 1.25,
      },
    },
    pageOtpSelectionInfo: {
      fontWeight: 700,
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.up('lg')]: {
        fontWeight: 400,
        flexShrink: 0,
        paddingLeft: 15,
      },
    },
    pageOtpSelectionPrice: {
      [theme.breakpoints.up('lg')]: {
        fontSize: 24,
        lineHeight: 1.25,
      },
    },
    pageOtpSelectionFee: {
      paddingLeft: 10,
      [theme.breakpoints.up('lg')]: {
        paddingLeft: 28,
      },
    },
    pageOtpBenefits: {
      paddingLeft: 8,
      marginBottom: 14,
      [theme.breakpoints.up('lg')]: {
        paddingLeft: 0,
        marginBottom: 10,
      },
    },
    pageOtpBenefitsTitle: {
      fontSize: 24,
      lineHeight: 1.25,
    },
    pageOtpBenefitsInfo: {
      paddingLeft: 10,
      marginTop: 8,
      display: 'flex',
      alignItems: 'flex-start',
      [theme.breakpoints.up('lg')]: {
        paddingLeft: 0,
        marginTop: 10,
      },
    },
    pageOtpBenefitsInfoText: {
      fontSize: 12,
      lineHeight: 2,
      color: theme.custom.lightText,
      [theme.breakpoints.up('lg')]: {
        fontSize: 14,
        lineHeight: 1.7143,
      },
    },
    pageOtpBenefitsInfoIcon: {
      cursor: 'pointer',
      color: theme.custom.darkGray,
      fontSize: 18,
      lineHeight: 1,
      marginTop: 3,
      marginLeft: 20,
    },
    // HHM Gating.
    hhmGatingHeaderClose: {
      position: 'fixed',
      right: 16,
      top: 18,
      zIndex: 102,
      minWidth: 0,
      padding: 0,
      color: theme.custom.slate,
      fontSize: 36,
      [theme.breakpoints.up('lg')]: {
        top: 27,
      },
    },
    hhmGating: {
      maxWidth: 521,
      marginLeft: 'auto',
      marginRight: 'auto',
      padding: 24,
      textAlign: 'center',
      color: theme.custom.slate,
      boxShadow: '0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08)',
      [theme.breakpoints.up('lg')]: {
        padding: 48,
      },
    },
    hhmGatingTitle: {
      fontSize: 24,
      lineHeight: 1.29167,
      marginBottom: 24,
    },
    hhmGatingDescription: {
      marginBottom: 48,
    },
    hhmGatingBtns: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 48,
      '& button': {
        minWidth: 140,
        margin: '0 8px',
      },
    },
    // Show more.
    showMore: {
      position: 'relative',
      color: theme.custom.darkGray,
      fontSize: 14,
      lineHeight: 1.7143,
      '& .icon': {
        fontSize: 16,
        color: theme.custom.darkGray,
        position: 'absolute',
        left: 0,
        top: 5,
      },
      '&.collapsed': {
        paddingLeft: 24,
        cursor: 'pointer',
        textDecoration: 'underline',
      },
      '&.expanded': {
        paddingTop: 30,
      },
      '&.small': {
        fontSize: 12,
        lineHeight: 1.35,
        '&.collapsed': {
          paddingTop: 4,
        },
        '&.expanded': {
          paddingTop: 26,
        },
      },
    },
    // Dialog Error.
    dialogError: {
      width: 'calc(100% - 30px)',
      maxWidth: 573,
      margin: 15,
      borderRadius: 4,
      overflowY: 'visible',
    },
    dialogErrorContainer: {
      padding: 48,
    },
    dialogErrorContent: {
      color: theme.custom.black,
      display: 'flex',
      overflowWrap: 'break-word',
      padding: '32px 20px 45px 32px',
      backgroundColor: alpha(String(theme.custom.errorRed), 0.12),
    },
    dialogErrorIcon: {
      color: theme.custom.errorRed,
      fontSize: 28,
      marginRight: 16,
      flexShrink: 0,
    },
    dialogErrorMessage: {
      flexGrow: 1,
      letterSpacing: '-0.02em',
      fontSize: 22,
      lineHeight: 1.3,
      paddingTop: 4,
    },
    dialogErrorTitle: {
      fontSize: 24,
      fontWeight: 700,
      marginBottom: 16,
    },
    dialogErrorSubTitle: {
      color: theme.custom.errorRed,
    },
    dialogErrorText: {
      '& .kustomer_btn': {
        fontSize: 'inherit',
        padding: 0,
        minWidth: 'inherit',
        fontWeight: 'inherit',
        fontFamily: 'inherit',
        lineHeight: 'inherit',
        letterSpacing: 'inherit',
        verticalAlign: 'inherit',
        color: theme.custom.blue,
      },
    },
    // Account styles.
    // Dialog styles.
    accountDialog: {
      width: 'calc(100% - 30px)',
      maxWidth: 830,
      margin: '60px 15px 15px 15px',
      borderRadius: 0,
      color: theme.custom.slate,
      fontFamily: theme.custom.akkurat,
      overflowY: 'visible',
      [theme.breakpoints.up('lg')]: {
        margin: '30px 0',
      },
    },
    accountDialogWide: {
      width: 'calc(100% - 30px)',
      maxWidth: 1364,
      margin: '60px 15px 15px 15px',
      borderRadius: 0,
      color: theme.custom.slate,
      fontFamily: theme.custom.akkurat,
      overflowY: 'visible',
      [theme.breakpoints.up('lg')]: {
        margin: '30px 0',
      },
    },
    accountDialogHeader: {
      position: 'relative',
      backgroundColor: theme.custom.lightGrayDark,
      padding: '9px 30px',
      [theme.breakpoints.up('lg')]: {
        padding: '17px 50px',
      },
    },
    accountDialogHeaderClose: {
      position: 'absolute',
      right: 0,
      top: -50,
      width: 35,
      height: 35,
      backgroundColor: theme.custom.lightestGray,
      color: theme.custom.darkGray,
      '& .icon': {
        fontSize: 16,
      },
      '&:hover': {
        backgroundColor: theme.custom.slate,
        color: theme.custom.white,
      },
      [theme.breakpoints.up('lg')]: {
        right: 10,
        top: 20,
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: 'transparent',
          color: 'inherit',
        },
      },
    },
    accountDialogTitle: {
      fontSize: 22,
      lineHeight: 1.3182,
      letterSpacing: '-0.02em',
      [theme.breakpoints.up('lg')]: {
        fontSize: 32,
        lineHeight: 1.3125,
      },
    },
    accountDialogContent: {
      padding: 30,
      '& .dialog_actions': {
        padding: '30px 0 0',
      },
      [theme.breakpoints.up('lg')]: {
        padding: 50,
        '& .dialog_actions': {
          padding: '50px 0 0',
        },
      },
    },
    accountDialogContentNarrow: {
      maxWidth: 592,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    accountDialogContentSubTitle: {
      fontSize: 24,
      lineHeight: 1.29167,
      marginBottom: 24,
    },
    accountDialogActions: {
      display: 'block',
      textAlign: 'center',
      padding: '0px 30px 30px',
      '& .note': {
        marginTop: 24,
      },
      '& button': {
        width: '100%',
      },
      '& button + button': {
        marginTop: 16,
      },
      [theme.breakpoints.up('lg')]: {
        padding: '50px 0;',
        '& button': {
          width: 'auto',
          marginLeft: 'auto',
          marginRight: 'auto',
          minWidth: 280,
          display: 'flex',
        },
      },
    },
    accountDialogActionsRow: {
      [theme.breakpoints.up('lg')]: {
        display: 'flex',
        padding: '0 50px 50px',
        justifyContent: 'flex-start',
        '& button': {
          minWidth: 0,
          marginLeft: 0,
          marginRight: 20,
        },
        '& button + button': {
          marginTop: 0,
        },
      },
    },
    accountDialogActionsRowError: {
      color: theme.custom.errorRed,
      fontSize: 18,
      marginBottom: 16,
      [theme.breakpoints.up('lg')]: {
        padding: '0 50px 0',
      },
    },
    // Tooltip.
    accountTooltip: {
      pointerEvents: 'auto',
    },
    accountTooltipClose: {
      padding: 0,
      color: theme.custom.white,
      fontSize: 16,
      position: 'absolute',
      right: 10,
      top: 10,
    },
    accountTooltipActions: {
      marginTop: 18,
    },
    accountTooltipAction: {
      padding: '0 16px',
      textTransform: 'uppercase',
      textDecoration: 'none',
      borderRadius: 2,
      lineHeight: '25px',
      '&.no': {
        backgroundColor: theme.custom.white,
        fontSize: 14,
      },
      '&.yes': {
        color: theme.custom.white,
      },
    },
    // Popup block.
    accountPopupBlock: {
      position: 'relative',
      zIndex: 1000,
      backgroundColor: theme.custom.slate,
      borderRadius: 2,
      maxWidth: 236,
      padding: 20,
      boxShadow: '0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);',
      '&::before': {
        content: '""',
        width: 0,
        height: 0,
        borderStyle: 'solid',
        borderWidth: '6px 6px 0 6px',
        borderColor: `${theme.custom.slate} transparent transparent transparent`,
        position: 'absolute',
        left: 16,
        top: '100%',
      },
    },
    accountPopupBlockClose: {
      padding: 0,
      color: theme.custom.white,
      fontSize: 16,
      position: 'absolute',
      right: 10,
      top: 10,
    },
    accountPopupBlockContent: {
      color: theme.custom.white,
      fontSize: 14,
    },
    // General styles.
    accountStyleguide: {},
    accountStyleguideCheckboxSmall: {
      fontSize: 12,
    },
    accountStyleguideSwitchSmall: {
      fontSize: 10,
    },
    accountWrapper: {
      position: 'relative',
    },
    // Account Content.
    accountContentWrapper: {
      position: 'relative',
      padding: '24px 0',
      overflowX: 'hidden',
      [theme.breakpoints.up('lg')]: {
        padding: '30px 0',
      },
    },
    accountContentInner: {
      position: 'relative',
      margin: '0 auto',
      padding: '0 15px',
      [theme.breakpoints.up('md')]: {
        maxWidth: 718, // 688 + 30
      },
      [theme.breakpoints.up('lg')]: {
        maxWidth: 1140, // 1110 + 30
        display: 'flex',
      },
    },
    accountSidebar: {
      [theme.breakpoints.up('lg')]: {
        width: 280,
        marginRight: 60,
        flexShrink: 0,
      },
    },
    accountSidebarMember: {
      display: 'none',
      marginBottom: 30,
      paddingBottom: 15,
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: theme.custom.mediumGray,
      [theme.breakpoints.up('lg')]: {
        display: 'block',
        marginBottom: 0,
        paddingBottom: 0,
        borderBottom: 'none',
      },
    },
    accountSidebarMemberAdminLink: {
      fontSize: 14,
      fontWeight: 'bold',
    },
    accountSidebarMemberInfo: {
      flexGrow: 1,
      marginLeft: 20,
      [theme.breakpoints.up('lg')]: {
        marginLeft: 0,
      },
    },
    accountSidebarMemberInfoTravelProtection: {
      '& span': {
        color: theme.custom.darkGray,
      },
    },
    accountSidebarLabelWithValue: {
      marginBottom: 16,
    },
    accountSidebarLabel: {
      fontSize: 12,
      fontFamily: theme.custom.akkuratMono,
      letterSpacing: '0.16em',
      color: theme.custom.darkestGray,
      textTransform: 'uppercase',
    },
    accountSidebarValue: {
      fontSize: 14,
      display: 'flex',
    },
    accountContent: {
      [theme.breakpoints.up('lg')]: {
        width: 'calc(100% - 340px)',
      },
    },
    accountTitle: {
      display: 'none',
      marginBottom: 30,
      [theme.breakpoints.up('lg')]: {
        display: 'block',
        marginBottom: 41,
      },
    },
    accountSubTitle: {
      marginBottom: 30,
      [theme.breakpoints.up('lg')]: {
        marginBottom: 41,
      },
    },
    accountFieldWrapper: {
      marginBottom: 24,
      '&.error': {
        '& $accountFieldLabel': {
          color: theme.custom.errorRed,
        },
      },
    },
    accountFieldLabel: {
      marginBottom: 8,
      display: 'block',
      fontFamily: theme.custom.akkuratMono,
      textTransform: 'uppercase',
      fontSize: 12,
      fontWeight: 400,
      lineHeight: 1.214,
      letterSpacing: '0.16em',
      '& span': {
        fontSize: 10,
        letterSpacing: '0.1em',
      },
    },
    accountCardElement: {
      padding: '18px 16px 17px 16px',
      borderRadius: 2,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.custom.slate,
    },
    cardElementNoBorder: {
      borderColor: 'transparent',
      background: theme.custom.white,
    },
    accountFieldErrorMessage: {
      fontSize: 14,
      paddingTop: 5,
      color: theme.custom.errorRed,
    },
    accountFieldHelp: {
      fontSize: 14,
      paddingTop: 5,
      color: theme.custom.slate,
      '& a': {
        color: theme.custom.darkGray,
        fontSize: 'inherit',
        padding: 0,
        minWidth: 'inherit',
        fontWeight: 'inherit',
        fontFamily: 'inherit',
        lineHeight: 'inherit',
        letterSpacing: 'inherit',
        verticalAlign: 'inherit',
      },
    },
    accountBaseInputView: {
      display: 'flex',
      alignItems: 'center',
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: theme.custom.mediumGray,
      padding: '15px 12px 14px',
      cursor: 'default',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      '& .icon': {
        fontSize: 20,
        color: theme.custom.successGreen,
        marginRight: 12,
      },
    },
    accountBaseInputViewBtn: {
      fontWeight: 400,
      padding: 0,
    },
    colOneFourth: {
      [theme.breakpoints.up('lg')]: {
        maxWidth: '25%',
        flex: '0 0 25%',
      },
    },
    // Profile page.
    accountProfileAvatarDialogImgInfo: {
      marginBottom: 20,
      '& span': {
        display: 'block',
        color: theme.custom.errorRed,
      },
    },
    accountProfileBirthdateIcon: {
      fontSize: 20,
      color: theme.custom.darkGray,
      marginRight: 5,
    },
    // Give back page.
    accountGiveBackSubTitle: {
      fontSize: 18,
      lineHeight: 1.61112,
      marginBottom: 24,
      [theme.breakpoints.up('lg')]: {
        fontSize: 24,
        lineHeight: 1.29167,
        marginBottom: 48,
      },
    },
    accountGiveBackInfo: {
      color: theme.custom.darkGray,
      marginBottom: 48,
    },
    accountGiveBackMore: {
      display: 'block',
      color: theme.custom.darkGray,
      fontSize: 14,
      padding: 0,
      marginTop: 24,
    },
    accountGiveBackAmount: {
      maxWidth: 330,
    },
    accountGiveBackBtn: {
      width: '100%',
      marginTop: 24,
      [theme.breakpoints.up('lg')]: {
        width: 'auto',
      },
    },
    // Payment page.
    accountPaymentListLoader: {
      textAlign: 'center',
    },
    // Communication preferences page.
    accountCommunicationBtn: {
      minWidth: 225,
      [theme.breakpoints.up('lg')]: {
        minWidth: 0,
      },
    },
    // Programs page.
    accountManageProgramsEmpty: {
      fontSize: 18,
      fontWeight: 700,
      borderRadius: 4,
      backgroundColor: theme.custom.lightestGray,
      padding: '18px 20px',
      [theme.breakpoints.up('lg')]: {
        padding: '29px 23px',
      },
    },
    accountManageProgramsActiveProgram: {
      padding: 20,
      borderRadius: 4,
      backgroundColor: theme.custom.lightestGray,
      [theme.breakpoints.up('lg')]: {
        padding: '24px 23px',
      },
    },
    accountManageProgramsActiveProgramTitle: {
      fontSize: 24,
      lineHeight: 1.3,
    },
    accountManageProgramsActiveProgramDate: {
      color: theme.custom.darkGray,
      fontFamily: theme.custom.akkuratMono,
      fontSize: 12,
      letterSpacing: '0.16em',
      textTransform: 'uppercase',
      marginTop: 8,
    },
    accountManageProgramsActiveProgramContent: {
      marginTop: 32,
    },
    // Manage subscription page.
    accountManageSubscriptionPlan: {
      marginBottom: 34,
    },
    accountManageSubscriptionPlanContent: {
      backgroundColor: theme.custom.lightGrayDark,
      padding: 20,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 16,
    },
    accountManageSubscriptionPlanContentInfo: {
      flexGrow: 1,
      maxWidth: '100%',
      [theme.breakpoints.up('lg')]: {
        display: 'flex',
        flexWrap: 'wrap',
      },
    },
    accountManageSubscriptionPlanContentInfoBtns: {
      [theme.breakpoints.up('lg')]: {
        flexShrink: 0,
        marginLeft: 'auto',
      },
    },
    accountManageSubscriptionPlanContentInfoBtn: {
      marginTop: 40,
      width: '100%',
      [theme.breakpoints.up('lg')]: {
        marginTop: 0,
        marginLeft: 10,
        width: 'auto',
      },
    },
    accountManageSubscriptionPlanPromo: {
      backgroundColor: theme.custom.lightGrayDark,
      padding: 20,
      display: 'flex',
      alignItems: 'center',
      marginBottom: 16,
    },
    accountManageSubscriptionPlanPromoIcon: {
      fontSize: 32,
    },
    accountManageSubscriptionPlanPromoProgram: {
      marginLeft: 10,
    },
    accountManageSubscriptionTravelProtection: {
      marginBottom: 34,
    },
    accountManageSubscriptionTravelProtectionStatus: {
      marginBottom: 20,
      '&.active': {
        '& $accountManageSubscriptionTravelProtectionStatusIcon': {
          backgroundColor: theme.custom.successGreen,
        },
        '& $accountManageSubscriptionTravelProtectionStatusInfo': {
          color: theme.custom.successGreen,
        },
      },
      '&.inactive': {
        '& $accountManageSubscriptionTravelProtectionStatusIcon': {
          backgroundColor: theme.custom.errorRed,
        },
        '& $accountManageSubscriptionTravelProtectionStatusInfo': {
          color: theme.custom.errorRed,
        },
      },
      [theme.breakpoints.up('lg')]: {
        display: 'flex',
        alignItems: 'center',
      },
    },
    accountManageSubscriptionTravelProtectionStatusContent: {
      flexGrow: 1,
      display: 'flex',
      alignItems: 'center',
    },
    accountManageSubscriptionTravelProtectionStatusIcon: {
      width: 32,
      height: 32,
      borderRadius: 32,
      marginRight: 10,
      display: 'flex',
      flexShrink: 0,
      alignItems: 'center',
      justifyContent: 'center',
      color: 'transparent',
      '& .icon': {
        fontSize: 20,
      },
    },
    accountManageSubscriptionTravelProtectionStatusInfo: {
      padding: 0,
    },
    // Cancel plan.
    accountDialogCancelPlanShort: {
      padding: 0,
    },
    accountDialogCancelPlanTitle: {
      fontSize: 22,
      lineHeight: 1.3182,
      marginBottom: 32,
      [theme.breakpoints.up('lg')]: {
        fontSize: 24,
        lineHeight: 1.33334,
      },
    },
    accountDialogCancelPlanCancel: {
      display: 'block',
      fontSize: 14,
      lineHeight: 2.07143,
      padding: '10px 24px',
      margin: '16px auto 0',
    },
    accountDialogCancelPlanShortRow: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      marginLeft: -12,
      marginRight: -12,
      [theme.breakpoints.up('lg')]: {
        marginBottom: 24,
      },
    },
    accountDialogCancelPlanShortCol: {
      position: 'relative',
      width: '100%',
      paddingLeft: 12,
      paddingRight: 12,
      [theme.breakpoints.up('lg')]: {
        maxWidth: '33.333333%',
        flex: '0 0 33.333333%',
      },
    },
    accountDialogCancelPlanShortItem: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: 24,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.custom.border,
      borderRadius: 8,
      marginBottom: 24,
      [theme.breakpoints.up('lg')]: {
        height: '100%',
        marginBottom: 0,
      },
    },
    accountDialogCancelPlanShortItemActive: {
      border: 'none',
      borderRadius: 0,
      backgroundColor: theme.custom.slate,
      color: theme.custom.white,
      '& $accountDialogCancelPlanShortItemTitle': {
        marginBottom: 24,
      },
      '& $accountDialogCancelPlanShortItemText': {
        fontSize: 18,
        lineHeight: 1.61112,
        marginBottom: 48,
        [theme.breakpoints.up('lg')]: {
          fontSize: 16,
          lineHeight: 1.5625,
        },
      },
    },
    accountDialogCancelPlanShortItemTitle: {
      fontSize: 24,
      lineHeight: 1.29167,
      letterSpacing: '-0.02em',
      marginBottom: 30,
      [theme.breakpoints.up('lg')]: {
        fontSize: 20,
        lineHeight: 1.3,
      },
    },
    accountDialogCancelPlanShortItemText: {
      fontSize: 16,
      lineHeight: 1.5625,
      marginBottom: 24,
      '& ul': {
        paddingLeft: 20,
        margin: '12px 0 0',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 14,
        lineHeight: 1.57143,
        marginBottom: 32,
      },
    },
    accountDialogCancelPlanShortItemBtn: {
      borderColor: theme.custom.white,
      backgroundColor: theme.custom.slate,
      color: theme.custom.white,
      fontSize: 14,
      lineHeight: 2.07143,
      padding: '9px 24px',
      borderRadius: 0,
      '&:hover': {
        borderColor: theme.custom.white,
        backgroundColor: theme.custom.slate,
        color: theme.custom.white,
      },
    },
    accountDialogCancelPlanShortItemLink: {
      padding: 0,
      marginTop: 'auto',
      [theme.breakpoints.up('lg')]: {
        fontSize: 12,
        lineHeight: 1.58334,
      },
    },
    accountDialogCancelPlanFull: {
      padding: 0,
    },
    accountDialogCancelPlanFullContent: {
      padding: '24px 16px',
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.custom.border,
      borderRadius: 8,
      [theme.breakpoints.up('lg')]: {
        padding: 24,
      },
    },
    accountDialogCancelPlanFullContentTitle: {
      fontSize: 18,
      lineHeight: 1.33334,
      letterSpacing: '-0.02em',
      marginBottom: 24,
      [theme.breakpoints.up('lg')]: {
        fontSize: 24,
      },
    },
    accountDialogCancelPlanFullBenefitsRow: {
      display: 'flex',
      flexWrap: 'wrap',
      marginLeft: -12,
      marginRight: -12,
      [theme.breakpoints.up('lg')]: {
        marginBottom: 24,
      },
    },
    accountDialogCancelPlanFullBenefitsCol: {
      position: 'relative',
      width: '100%',
      paddingLeft: 12,
      paddingRight: 12,
      maxWidth: '50%',
      flex: '0 0 50%',
      marginBottom: 24,
      [theme.breakpoints.up('lg')]: {
        maxWidth: '33.333333%',
        flex: '0 0 33.333333%',
      },
    },
    accountDialogCancelPlanFullBenefit: {
      display: 'block',
      height: '100%',
      minHeight: 110,
      textAlign: 'center',
      color: theme.custom.slate,
      textDecoration: 'none',
      fontSize: 14,
      lineHeight: 1.57143,
      padding: '16px 5px 7px',
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.custom.slate,
      '& .icon': {
        display: 'block',
        fontSize: 24,
        color: theme.custom.slate,
        margin: '0 auto 8px',
      },
      [theme.breakpoints.up('lg')]: {
        paddingTop: 20,
        minHeight: 92,
      },
    },
    accountDialogCancelPlanFullExclusivesRow: {
      display: 'flex',
      flexWrap: 'wrap',
      marginLeft: -12,
      marginRight: -12,
      marginBottom: 24,
    },
    accountDialogCancelPlanFullExclusivesCol: {
      position: 'relative',
      width: '100%',
      paddingLeft: 12,
      paddingRight: 12,
      marginBottom: 24,
      [theme.breakpoints.up('lg')]: {
        maxWidth: '50%',
        flex: '0 0 50%',
      },
    },
    accountDialogCancelPlanFullExclusive: {
      display: 'block',
      textDecoration: 'none',
      color: 'inherit',
      background: theme.custom.lightGrayDark,
      height: '100%',
    },
    accountDialogCancelPlanFullExclusiveImage: {
      height: 124,
      '& .img': {
        display: 'block',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      },
      [theme.breakpoints.up('lg')]: {
        height: 111,
      },
    },
    accountDialogCancelPlanFullExclusiveType: {
      background: theme.custom.slate,
      color: theme.custom.white,
      fontSize: 14,
      lineHeight: 1.57143,
      fontWeight: 700,
      padding: '4px 16px',
      marginBottom: 16,
    },
    accountDialogCancelPlanFullExclusiveLogo: {
      margin: '0 16px 16px',
      maxWidth: 128,
      '& .logo': {
        display: 'block',
        maxWidth: '100%',
        height: 'auto',
      },
      [theme.breakpoints.up('lg')]: {
        maxWidth: 160,
      },
    },
    accountDialogCancelPlanFullExclusiveDescription: {
      padding: '0 16px 16px',
      fontSize: 16,
      lineHeight: 1.3125,
    },
    accountDialogCancelPlanFullContentBtnWrapper: {
      textAlign: 'center',
    },
    accountDialogCancelPlanFullContentBtn: {
      width: '100%',
      fontSize: 12,
      textAlign: 'center',
      lineHeight: '28px',
      '& .icon': {
        fontSize: 16,
        marginRight: 8,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 16,
        width: 'auto',
        minWidth: 288,
        margin: '0 auto',
      },
    },
    accountDialogCancelPlanMid: {
      padding: 0,
    },
    accountDialogCancelPlanMidTitle: {
      fontSize: 22,
      lineHeight: 1.3182,
      fontWeight: 700,
      marginBottom: 56,
      [theme.breakpoints.up('lg')]: {
        fontSize: 24,
        lineHeight: 1.29167,
      },
    },
    accountDialogCancelPlanMidContent: {
      marginBottom: 24,
    },
    accountDialogCancelPlanMidContentRow: {
      display: 'flex',
      flexWrap: 'wrap',
      marginLeft: -12,
      marginRight: -12,
    },
    accountDialogCancelPlanMidContentCol: {
      position: 'relative',
      width: '100%',
      paddingLeft: 12,
      paddingRight: 12,
      marginBottom: 24,
      [theme.breakpoints.up('lg')]: {
        maxWidth: '50%',
        flex: '0 0 50%',
      },
    },
    accountDialogCancelPlanMidItem: {
      borderRadius: '4px 4px 0 0',
      backgroundColor: theme.custom.white,
      overflow: 'hidden',
      boxShadow: '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
      height: '100%',
    },
    accountDialogCancelPlanMidItemHeader: {
      textAlign: 'center',
      fontWeight: 700,
      fontSize: 18,
      lineHeight: 1.61112,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 18,
      '& .icon': {
        fontSize: 24,
        marginRight: 10,
      },
      '&.left': {
        backgroundColor: theme.custom.gold,
      },
      '&.right': {
        backgroundColor: theme.custom.lightMint,
      },
    },
    accountDialogCancelPlanMidItemLeftContent: {
      padding: '32px 24px 48px',
    },
    accountDialogCancelPlanMidItemLeftTitle: {
      textAlign: 'center',
      fontWeight: 700,
      fontSize: 18,
      lineHeight: 1.38889,
      marginBottom: 24,
    },
    accountDialogCancelPlanMidItemLeftOldPrice: {
      textAlign: 'center',
      color: theme.custom.darkGray,
      fontSize: 24,
      lineHeight: 1.29167,
      textDecoration: 'line-through',
      marginBottom: 16,
    },
    accountDialogCancelPlanMidItemLeftPrice: {
      textAlign: 'center',
      fontWeight: 300,
      letterSpacing: '-0.02em',
      fontSize: 66,
      lineHeight: 1,
      marginBottom: 24,
      '& span': {
        fontWeight: 400,
        fontSize: 24,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 88,
      },
    },
    accountDialogCancelPlanMidItemLeftText: {
      '& ul': {
        margin: '0 0 48px',
        paddingLeft: 20,
      },
      '& li': {
        marginBottom: 16,
      },
    },
    accountDialogCancelPlanMidItemLeftBtnWrapper: {
      [theme.breakpoints.up('lg')]: {
        margin: '0 24px',
      },
    },
    accountDialogCancelPlanMidItemLeftBtn: {
      width: '100%',
    },
    accountDialogCancelPlanMidItemRightContent: {
      padding: '24px 24px 24px 37px',
      fontSize: 14,
      lineHeight: 1.57143,
      '& ul': {
        margin: 0,
        padding: 0,
        listStyle: 'none',
      },
      '& li': {
        position: 'relative',
        paddingLeft: 34,
        marginBottom: 23,
        '&::before': {
          content: '""',
          display: 'block',
          width: 13,
          height: 7,
          borderStyle: 'solid',
          borderWidth: 0,
          borderColor: theme.custom.darkGray,
          borderLeftWidth: 2,
          borderBottomWidth: 2,
          transform: 'rotate(-45deg)',
          position: 'absolute',
          left: 0,
          top: 5,
        },
      },
    },
    accountManageSubscriptionCancelSubscriptionImmediatelyDescriptionText: {
      color: theme.custom.errorRed,
    },
    accountManageSubscriptionSubscriptionSaveInline: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 34,
      [theme.breakpoints.up('lg')]: {
        flexDirection: 'row',
        alignItems: 'flex-start',
        '& .select': {
          width: 328,
        },
        '& .btn': {
          width: 280,
          marginLeft: 10,
          marginTop: 22,
        },
      },
    },
    // Invoice info.
    accountDialogInvoiceInfoHeader: {
      marginBottom: 40,
    },
    accountDialogInvoiceInfoHeaderTag: {
      display: 'inline-block',
      fontFamily: theme.custom.akkuratMono,
      fontSize: 12,
      lineHeight: 1.58334,
      textTransform: 'uppercase',
      letterSpacing: '0.16em',
      padding: '2px 10px',
      backgroundColor: theme.custom.mint,
      marginBottom: 18,
      marginRight: 10,
      '&.paid': {
        backgroundColor: theme.custom.mint,
      },
      '&.draft': {
        backgroundColor: theme.custom.gold,
      },
      '&.open': {
        backgroundColor: theme.custom.errorRed,
      },
      '&.uncollectible': {
        backgroundColor: theme.custom.mediumGray,
      },
      '&.void': {
        backgroundColor: theme.custom.mediumGray,
      },
    },
    accountDialogInvoiceInfoHeaderId: {
      fontSize: 18,
      lineHeight: 1.38889,
      fontWeight: 700,
      textTransform: 'uppercase',
      wordBreak: 'break-all',
      marginBottom: 20,
    },
    accountDialogInvoiceInfoHeaderTotal: {
      fontFamily: theme.custom.akkuratMono,
      fontSize: 12,
      lineHeight: 1.58334,
      textTransform: 'uppercase',
      letterSpacing: '0.16em',
      marginBottom: 20,
    },
    accountDialogInvoiceInfoHeaderPeriod: {
      color: theme.custom.darkestGray,
      fontFamily: theme.custom.akkuratMono,
      fontSize: 12,
      lineHeight: 1.58334,
      textTransform: 'uppercase',
      letterSpacing: '0.16em',
      marginBottom: 6,
    },
    accountDialogInvoiceInfoHeaderBtns: {
      '& .icon': {
        fontSize: 16,
        marginRight: 6,
      },
    },
    accountDialogInvoiceInfoHeaderBtn: {
      width: '100%',
      marginTop: 20,
      [theme.breakpoints.up('lg')]: {
        width: 'auto',
        marginRight: 20,
      },
    },
    accountDialogInvoiceInfoSummary: {
      marginBottom: 20,
    },
    accountDialogInvoiceInfoSummaryTitle: {
      color: theme.custom.black,
      fontSize: 32,
      lineHeight: 1.3125,
      letterSpacing: '-0.02em',
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: theme.custom.slate,
      paddingBottom: 15,
      marginBottom: 20,
    },
    accountDialogInvoiceInfoSummaryInfo: {
      padding: 0,
    },
    accountDialogInvoiceInfoSummaryLeft: {
      [theme.breakpoints.up('lg')]: {
        '& $accountDialogInvoiceInfoSummaryItemLabel': {
          minWidth: 70,
        },
      },
    },
    accountDialogInvoiceInfoSummaryRight: {
      [theme.breakpoints.up('lg')]: {
        '& $accountDialogInvoiceInfoSummaryItem': {
          textAlign: 'right',
        },
        '& $accountDialogInvoiceInfoSummaryItemLabel': {
          minWidth: 140,
        },
      },
    },
    accountDialogInvoiceInfoSummaryItem: {
      display: 'flex',
      fontSize: 12,
      marginBottom: 20,
      [theme.breakpoints.up('lg')]: {
        fontSize: 16,
      },
    },
    accountDialogInvoiceInfoSummaryItemLabel: {
      minWidth: 100,
      marginRight: 15,
      color: theme.custom.darkGray,
      flexShrink: 0,
    },
    accountDialogInvoiceInfoSummaryItemText: {
      color: theme.custom.black,
      flexGrow: 1,
    },
    accountDialogInvoiceInfoTable: {
      fontSize: 12,
      color: theme.custom.black,
      display: 'flex',
      marginBottom: 10,
      [theme.breakpoints.up('lg')]: {
        fontSize: 18,
      },
      '& .col1': {
        width: '40%',
      },
      '& .col2': {
        width: '20%',
        textAlign: 'center',
      },
      '& .col3': {
        width: '20%',
        textAlign: 'center',
      },
      '& .col4': {
        width: '20%',
        textAlign: 'right',
      },
      '&.content': {
        color: theme.custom.darkGray,
        fontSize: 12,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.custom.mediumGray,
        padding: '19px 15px',
        borderRadius: 2,
        marginBottom: 5,
        [theme.breakpoints.up('lg')]: {
          fontSize: 16,
        },
      },
      '&.gray': {
        backgroundColor: theme.custom.lightestGray,
      },
    },
    accountDialogInvoiceInfoSubTotal: {
      padding: '0 16px 0 10px',
      display: 'flex',
      maxWidth: 415,
      marginLeft: 'auto',
      marginTop: 12,
      marginBottom: 5,
    },
    accountDialogInvoiceInfoSubTotalLabel: {
      flexGrow: 1,
      fontSize: 18,
      lineHeight: 1.38889,
      fontWeight: 700,
    },
    accountDialogInvoiceInfoSubTotalText: {
      color: theme.custom.darkGray,
      paddingLeft: 10,
    },
    accountDialogInvoiceInfoTotal: {
      padding: '19px 15px 21px 9px',
      display: 'flex',
      alignItems: 'center',
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.custom.mediumGray,
      backgroundColor: theme.custom.lightestGray,
      maxWidth: 415,
      marginLeft: 'auto',
    },
    accountDialogInvoiceInfoTotalLabel: {
      flexGrow: 1,
      fontSize: 56,
      lineHeight: 1.07143,
      fontWeight: 300,
      letterSpacing: '-0.04em',
    },
    accountDialogInvoiceInfoTotalText: {
      paddingLeft: 10,
    },
    // Charge refund.
    accountDialogChargeRefundType: {
      [theme.breakpoints.up('lg')]: {
        maxWidth: 280,
      },
    },
    accountDialogChargeRefundAmount: {
      [theme.breakpoints.up('lg')]: {
        maxWidth: 178,
      },
    },
    accountDialogChargeRefundAmountAdornment: {
      pointerEvents: 'none',
      paddingRight: 11,
    },
    // Edit plan.
    accountDialogEditPlan: {
      maxWidth: 251,
      marginLeft: 'auto',
      marginRight: 'auto',
      [theme.breakpoints.up('lg')]: {
        '& $accountDialogActions button': {
          minWidth: '100%',
        },
      },
    },
    accountDialogEditPlanMember: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 22,
    },
    accountDialogEditPlanMemberIcon: {
      flexShrink: 0,
      fontSize: 65,
      marginRight: 13,
    },
    accountDialogEditPlanMemberImg: {
      flexShrink: 0,
      width: 65,
      borderRadius: '50%',
      marginRight: 13,
    },
    accountDialogEditPlanHHMInfoFieldWrapperNarrow: {
      display: 'block',
      position: 'relative',
      fontSize: 14,
      marginTop: 7,
      textTransform: 'capitalize',
    },
    accountDialogEditPlanHHMInfoFieldWrapper: {
      position: 'relative',
      wordBreak: 'break-word',
    },
    accountDialogEditPlanHHMInfoFieldInput: {
      width: 363,
      '& input': {
        paddingRight: '88px',
      },
    },
    accountDialogEditPlanHHMInfoFieldInputNarrow: {
      width: 189,
      paddingTop: 8,
      paddingBottom: 16,
    },
    accountDialogEditPlanHHMInfoFieldActionsNarrow: {
      position: 'absolute',
      left: 160,
      top: 0,
      display: 'flex',
      justifyContent: 'flex-end',
      width: 130,
    },
    accountDialogEditPlanHHMInfoFieldActions: {
      position: 'absolute',
      left: 238,
      top: 0,
      display: 'flex',
      justifyContent: 'flex-end',
      width: 130,
      height: '100%',
    },
    accountDialogEditPlanHHMInfoFieldActionsButton: {
      fontWeight: 'normal',
      padding: '0 5px',
    },
    accountDialogEditPlanHHMInfoFieldSelect: {
      position: 'absolute',
      left: '0px',
      top: 'calc(100% + 5px)',
      width: 273,
      height: 0,
      opacity: 0,
    },
    accountDialogEditPlanHHMInfoFieldActionsCancel: {
      fontWeight: 'normal',
      color: 'red',
      padding: '0 10px',
      '& svg': {
        fontSize: '1.2rem',
      },
    },
    accountDialogEditPlanHHMInfoLabel: {
      fontSize: 12,
      textTransform: 'uppercase',
      fontFamily: theme.custom.akkuratMono,
      color: theme.custom.darkestGray,
    },
    accountDialogEditPlanTravelProtection: {
      paddingTop: 22,
      marginTop: 22,
      borderTopWidth: 1,
      borderTopStyle: 'solid',
      borderTopColor: theme.custom.lightGrayDark,
      '&.error': {
        '& $accountDialogEditPlanTravelProtectionInfoBtn': {
          color: theme.custom.errorRed,
          marginTop: 0,
        },
        '& $accountDialogEditPlanTravelProtectionNote': {
          color: theme.custom.errorRed,
        },
      },
    },
    accountDialogEditPlanTravelProtectionLabel: {
      fontSize: 12,
      textTransform: 'uppercase',
      fontFamily: theme.custom.akkuratMono,
      color: theme.custom.darkestGray,
      marginBottom: 10,
    },
    accountDialogEditPlanTravelProtectionInfo: {
      display: 'flex',
      alignItems: 'center',
      '&.active': {
        color: theme.custom.successGreen,
        '& $accountDialogEditPlanTravelProtectionInfoIcon': {
          backgroundColor: theme.custom.successGreen,
        },
      },
      '&.inactive': {
        color: theme.custom.errorRed,
        '& $accountDialogEditPlanTravelProtectionInfoIcon': {
          backgroundColor: theme.custom.errorRed,
        },
      },
    },
    accountDialogEditPlanTravelProtectionInfoIcon: {
      width: 25,
      height: 25,
      borderRadius: 25,
      marginRight: 10,
      display: 'flex',
      flexShrink: 0,
      alignItems: 'center',
      justifyContent: 'center',
      color: 'transparent',
      '& .icon': {
        fontSize: 17,
      },
    },
    accountDialogEditPlanTravelProtectionInfoBtn: {
      fontSize: 14,
      fontWeight: 400,
      marginTop: 24,
    },
    accountDialogEditPlanTravelProtectionNote: {
      fontSize: 12,
    },
    accountDialogEditPlanRenewalDate: {
      paddingTop: 22,
      marginTop: 22,
      borderTopWidth: 1,
      borderTopStyle: 'solid',
      borderTopColor: theme.custom.lightGrayDark,
    },
    accountDialogEditPlanRenewalDateLabel: {
      fontSize: 12,
      textTransform: 'uppercase',
      fontFamily: theme.custom.akkuratMono,
      color: theme.custom.darkestGray,
      marginBottom: 10,
    },
    accountDialogEditPlanContact: {
      textAlign: 'center',
      marginTop: 15,
    },
    accountDialogEditPlanHHMInfoFieldOptionLabel: {
      textTransform: 'capitalize',
    },
    // HHM Setup.
    accountDialogHHMemberSetup: {
      maxWidth: 592,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    accountDialogHHMemberSetupTitle: {
      fontSize: 24,
      lineHeight: 1.29167,
      marginBottom: 24,
    },
    accountDialogHHMemberSetupInfo: {
      marginBottom: 24,
    },
    accountDialogHHMemberSetupDivider: {
      height: 1,
      background: theme.custom.mediumGray,
      marginTop: 24,
      marginBottom: 24,
    },
    // HHM Edit Actions.
    accountDialogEditHHMActionsWrapper: {
      marginTop: 24,
    },
    accountDialogEditHHMActionsRadio: {
      paddingTop: 5,
      paddingBottom: 5,
    },
    accountDialogEditHHMActions: {
      display: 'flex',
      paddingTop: 43,
      justifyContent: 'flex-start',
      width: 350,
      '& button': {
        minWidth: 0,
        paddingLeft: 32,
        paddingRight: 32,
        display: 'flex',
      },
      '& button + button': {
        marginLeft: 20,
      },
    },
    // Add Travel Protection.
    accountDialogAddTravelProtectionTop: {
      marginBottom: 24,
    },
    accountDialogAddTravelProtectionTopTitle: {
      fontSize: 24,
      lineHeight: 1.29167,
      marginBottom: 16,
    },
    accountDialogAddTravelProtectionBenefitsWrapper: {
      marginBottom: 24,
      padding: 24,
      background: theme.custom.lightestGray,
    },
    accountDialogAddTravelProtectionBenefits: {
      padding: 16,
      background: theme.custom.white,
      boxShadow: '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
    },
    accountDialogAddTravelProtectionBenefitsTitle: {
      textAlign: 'center',
      fontSize: 18,
      lineHeight: 1.27778,
      fontWeight: 700,
      paddingBottom: 24,
      marginBottom: 24,
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: theme.custom.mediumGray,
    },
    accountDialogAddTravelProtectionBenefitsListWrapper: {
      padding: '0 16px 8px',
      maxWidth: 287,
      margin: '0 auto',
      [theme.breakpoints.up('md')]: {
        maxWidth: 'none',
      },
    },
    accountDialogAddTravelProtectionBenefitsList: {
      padding: 0,
      margin: 0,
      listStyle: 'none',
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'flex-start',
      marginLeft: -12,
      marginRight: -12,
      '& li': {
        position: 'relative',
        width: '100%',
        paddingLeft: 12,
        paddingRight: 12,
        fontSize: 12,
        lineHeight: 1.58334,
        color: theme.custom.black,
        display: 'flex',
        alignItems: 'center',
        marginBottom: 24,
      },
      '& .icon': {
        fontSize: 32,
        background: theme.custom.lightMint,
        color: theme.custom.slate,
        borderRadius: 2,
        flexShrink: 0,
        marginRight: 16,
        alignSelf: 'flex-start',
      },
      [theme.breakpoints.up('md')]: {
        '& li': {
          fontSize: 16,
          lineHeight: 1.5625,
          flex: '0 0 50%',
          maxWidth: '50%',
        },
        '& .icon': {
          fontSize: 40,
        },
      },
    },
    accountDialogAddTravelProtection: {
      padding: 20,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.custom.border,
      borderRadius: 4,
    },
    accountDialogAddTravelProtectionTitle: {
      fontSize: 32,
      letterSpacing: '-0.02em',
    },
    accountDialogAddTravelProtectionInfo: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      margin: '12px 0 16px',
    },
    accountDialogAddTravelProtectionInfoFootnote: {
      position: 'relative',
      display: 'flex',
      gap: 10,
      color: theme.custom.darkGray,
      fontSize: 12,
      lineHeight: 1.35,
      marginBottom: 24,
      '& .icon': {
        fontSize: 16,
        color: theme.custom.darkGray,
      },
    },
    accountDialogAddTravelProtectionDivider: {
      height: 1,
      backgroundColor: theme.custom.border,
      margin: '24px 0',
    },
    accountDialogAddTravelProtectionControlLabel: {
      marginTop: 4,
      marginBottom: 4,
      marginRight: 0,
    },
    accountDialogAddTravelProtectionItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      margin: '4px 0',
    },
    accountDialogAddTravelProtectionItemValue: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    accountDialogAddTravelProtectionItemInactive: {
      color: theme.custom.mediumGray,
    },
    accountDialogAddTravelProtectionTotal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      fontSize: 40,
      lineHeight: 1.2,
      letterSpacing: '-0.03em',
      margin: '24px 0',
      '& span': {
        color: theme.custom.darkGray,
      },
    },
    accountDialogAddTravelProtectionBtn: {
      display: 'block',
      width: '100%',
      maxWidth: 377,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    accountDialogAddTravelProtectionAutocompleteWrapper: {
      position: 'relative',
      height: 54,
    },
    accountDialogAddTravelProtectionAutocompleteInner: {
      zIndex: 9999,
      position: 'absolute',
      height: 54,
      width: '100%',
      padding: 8,
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden',
    },
    accountDialogAddTravelProtectionAutocompleteTextField: {
      position: 'absolute',
      left: 0,
      top: 0,
    },
    accountDialogAddTravelProtectionAutocompleteItem: {
      display: 'flex',
      alignItems: 'center',
    },
    accountDialogAddTravelProtectionAutocompleteItemIcon: {
      width: 46,
      height: 34,
      display: 'block',
      marginRight: 16,
    },
    accountDialogAddTravelProtectionAutocompleteItemCard: {
      color: theme.custom.slate,
      lineHeight: 1.125,
    },
    accountDialogAddTravelProtectionAutocompleteItemExp: {
      fontSize: 14,
      color: theme.custom.darkGray,
    },
    // Payment Confirmation
    accountDialogPaymentConfirmation: {
      //
    },
    accountDialogPaymentConfirmationMethod: {
      padding: 20,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.custom.border,
      borderRadius: 4,
      marginBottom: 65,
    },
    accountDialogPaymentConfirmationMember: {
      padding: 20,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.custom.border,
      borderRadius: 4,
    },
    accountDialogPaymentConfirmationMemberInfoMessage: {
      marginBottom: 38,
      backgroundColor: theme.custom.lightMint,
      textAlign: 'center',
      padding: 16,
      fontSize: 18,
    },
    accountDialogPaymentConfirmationMemberTitle: {
      fontSize: 32,
      letterSpacing: '-0.02em',
    },
    accountDialogPaymentConfirmationMemberInfo: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      margin: '24px 0',
    },
    accountDialogPaymentConfirmationGroupTitle: {
      fontFamily: theme.custom.akkurat,
      fontWeight: 400,
      lineHeight: '42px',
      letterSpacing: '-0.02em',
      textAlign: 'left',
      fontSize: 32,
      marginTop: '24px',
    },
    accountDialogPaymentConfirmationUserGroupTitle: {
      marginTop: '24px',
    },
    accountDialogPaymentConfirmationUserItemTitle: {
      fontFamily: theme.custom.akkurat,
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '25px',
      letterSpacing: '-0.02em',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      color: theme.custom.slate,
      marginTop: '10px',
    },
    accountDialogPaymentConfirmationLineItemInfo: {
      fontFamily: theme.custom.akkurat,
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '25px',
      letterSpacing: '-0.02em',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      color: theme.custom.darkGray,
      marginTop: '10px',
    },
    accountDialogPaymentConfirmationMemberDivider: {
      height: 1,
      backgroundColor: theme.custom.border,
      margin: '36px 0',
    },
    accountDialogPaymentConfirmationMemberTotal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      fontSize: 40,
      lineHeight: 1.2,
      letterSpacing: '-0.03em',
      margin: '24px 0',
      '& span': {
        color: theme.custom.darkGray,
      },
    },
    // Manage Members.
    accountDialogManageMembers: {
      padding: 0,
      '& $accountDialogActions': {
        [theme.breakpoints.up('lg')]: {
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          alignItems: 'center',
          '& button': {
            minWidth: 258,
            marginLeft: 8,
            marginRight: 8,
          },
          '& button + button': {
            marginTop: 0,
          },
        },
      },
    },
    accountDialogManageMembersTitle: {
      fontSize: 24,
      lineHeight: 1.29167,
      marginBottom: 24,
    },
    accountDialogManageMembersActionsRow: {
      width: '100%',
      marginTop: 16,
      [theme.breakpoints.up('lg')]: {
        marginTop: 48,
        display: 'flex',
        justifyContent: 'center',
      },
    },
    accountDialogManageMembersActionsRowBtn: {
      [theme.breakpoints.up('lg')]: {
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    accountDialogManageMembersText: {
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: theme.custom.mediumGray,
      '& span': {
        display: 'block',
        fontSize: 14,
        color: theme.custom.darkGray,
        marginTop: 5,
      },
      paddingBottom: 30,
    },
    accountDialogManageMembersShowMore: {
      paddingTop: 24,
    },
    accountDialogManageMembersItems: {
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: theme.custom.mediumGray,
      padding: 0,
    },
    accountDialogManageMembersItem: {
      margin: '14px 0',
      position: 'relative',
      fontSize: 14,
      [theme.breakpoints.up('lg')]: {
        display: 'flex',
        flexWrap: 'nowrap',
        paddingRight: 20,
      },
    },
    accountDialogManageMembersItemClose: {
      position: 'absolute',
      right: 0,
      bottom: 2,
      padding: 0,
      color: theme.custom.errorRed,
      '& .icon': {
        fontSize: 18,
      },
      [theme.breakpoints.up('lg')]: {
        top: 2,
        bottom: 'auto',
      },
    },
    accountDialogManageMembersItemName: {
      fontWeight: 700,
      [theme.breakpoints.up('lg')]: {
        width: '20%',
        paddingRight: 10,
      },
    },
    accountDialogManageMembersItemRelationship: {
      textTransform: 'capitalize',
      [theme.breakpoints.up('lg')]: {
        width: '20%',
        paddingRight: 10,
      },
    },
    accountDialogManageMembersItemEmail: {
      wordBreak: 'break-all',
      [theme.breakpoints.up('lg')]: {
        width: '40%',
        paddingRight: 10,
      },
    },
    accountDialogManageMembersItemPrice: {
      paddingRight: 30,
      '& span': {
        color: theme.custom.errorRed,
        textDecoration: 'line-through',
      },
      [theme.breakpoints.up('lg')]: {
        width: '20%',
        paddingRight: 10,
      },
    },
    accountDialogManageMembersMaximum: {
      paddingTop: 20,
      textAlign: 'center',
      color: theme.custom.errorRed,
    },
    accountDialogManageMembersForm: {
      paddingTop: 30,
    },
    accountDialogManageMembersFormNoteWrapper: {
      position: 'relative',
    },
    accountDialogManageMembersFormNote: {
      position: 'absolute',
      left: 0,
      bottom: '100%',
      marginBottom: 10,
    },
    accountDialogManageMembersFormActions: {
      [theme.breakpoints.up('lg')]: {
        display: 'flex',
        alignItems: 'flex-start',
      },
    },
    accountDialogManageMembersFormCheckboxes: {
      flexGrow: 1,
    },
    // Privacy page.
    accountPrivacyMobileMenu: {
      position: 'relative',
      [theme.breakpoints.up('lg')]: {
        display: 'none',
      },
    },
    accountPrivacyMobileMenuBtn: {
      color: theme.custom.slate,
      borderColor: theme.custom.slate,
      fontSize: 16,
      lineHeight: 1.75,
      fontWeight: 400,
      paddingLeft: 20,
      '& .icon': {
        marginLeft: 'auto',
      },
    },
    accountPrivacyMobileMenuPopper: {
      position: 'absolute',
      marginTop: '2px!important',
      width: '100%',
      zIndex: 100,
    },
    accountPrivacyMobileMenuPaper: {
      boxShadow: 'none',
      borderRadius: 0,
      backgroundColor: 'transparent',
    },
    accountPrivacyMobileMenuList: {
      padding: '10px 0',
      margin: 0,
      backgroundColor: theme.custom.white,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.custom.slate,
      borderRadius: 2,
    },
    accountPrivacyMobileMenuItem: {
      display: 'flex',
      alignItems: 'center',
      color: theme.custom.darkGray,
      textDecoration: 'none',
      padding: '10px 20px',
      '&:hover': {
        color: theme.custom.slate,
        backgroundColor: theme.custom.lightGrayDark,
      },
    },
    accountDataPrivacyTabs: {
      display: 'none',
      [theme.breakpoints.up('lg')]: {
        display: 'block',
      },
    },
    accountDataPrivacyTabPanel: {
      marginTop: 30,
      [theme.breakpoints.up('lg')]: {
        marginTop: 40,
      },
    },
    // Brand Connection.
    accountBrandConnectionsInfo: {
      marginBottom: 40,
      '& p': {
        color: theme.custom.darkGray,
        marginBottom: 20,
        fontSize: 18,
      },
    },
    accountBrandConnectionsTitle: {
      fontSize: 24,
      marginTop: 40,
      marginBottom: 20,
    },
    accountBrandConnectionsEmpty: {
      textAlign: 'center',
      fontSize: 24,
    },
    accountBrandConnectionsList: {
      paddingBottom: 0,
    },
    accountBrandConnectionHeadings: {
      display: 'none',
      fontSize: 12,
      paddingBottom: 15,
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: theme.custom.lightGrayDark,
      [theme.breakpoints.up('lg')]: {
        display: 'flex',
      },
    },
    accountBrandConnectionHeadingName: {
      flex: '5 0 0%',
      paddingRight: 10,
    },
    accountBrandConnectionHeadingTime: {
      flex: '3 0 0%',
    },
    accountBrandConnectionHeadingStatus: {
      flex: '2 0 0%',
    },
    accountBrandConnectionHeadingAction: {
      flex: '2 0 0%',
      textAlign: 'right',
    },
    accountBrandConnectionBodyName: {
      flex: '5 0 0%',
      [theme.breakpoints.up('lg')]: {
        paddingRight: 10,
      },
    },
    accountBrandConnectionBodyTime: {
      flex: '3 0 0%',
    },
    accountBrandConnectionBodyStatus: {
      flex: '2 0 0%',
    },
    accountBrandConnectionBodyAction: {
      flex: '2 0 0%',
      [theme.breakpoints.up('lg')]: {
        textAlign: 'right',
      },
    },
    accountBrandConnectionWrapper: {
      paddingTop: 10,
      paddingBottom: 10,
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: theme.custom.lightGrayDark,
      [theme.breakpoints.up('lg')]: {
        display: 'flex',
        alignItems: 'center',
      },
    },
    accountBrandConnectionName: {
      display: 'flex',
      alignItems: 'center',
      paddingBottom: 10,
      [theme.breakpoints.up('lg')]: {
        paddingBottom: 0,
      },
    },
    accountBrandConnectionLogo: {
      width: 22,
      height: 22,
      marginRight: 8,
      boxShadow: `0px 2px 4px ${theme.custom.border}`,
      borderRadius: 2,
      padding: 3,
      fontSize: 12,
      '& > img': {
        height: 'auto',
      },
    },
    accountBrandConnectionBrandName: {
      fontSize: 18,
      [theme.breakpoints.up('lg')]: {
        fontSize: 16,
      },
    },
    accountBrandConnectionDate: {
      color: theme.custom.darkGray,
      paddingBottom: 10,
      '& span': {
        paddingRight: 10,
      },
      [theme.breakpoints.up('lg')]: {
        paddingBottom: 0,
        '& span': {
          display: 'none',
        },
      },
    },
    accountBrandConnectionStatus: {
      paddingBottom: 10,
      display: 'flex',
      alignItems: 'center',
      '& span': {
        paddingRight: 10,
        color: theme.custom.darkGray,
      },
      [theme.breakpoints.up('lg')]: {
        paddingBottom: 0,
        '& span': {
          display: 'none',
        },
      },
    },
    accountBrandConnectionStatusLabel: {
      display: 'flex',
      alignItems: 'center',
      fontSize: 14,
      fontFamily: theme.custom.akkuratMono,
      letterSpacing: '0.16em',
      textTransform: 'uppercase',
      '& .icon': {
        fontSize: 16,
        color: theme.custom.successGreen,
        marginLeft: 10,
      },
    },
    accountBrandConnectionBtn: {
      padding: 0,
    },
    // Login.
    accountLoginWrapper: {
      [theme.breakpoints.up('lg')]: {
        maxWidth: 488,
      },
    },
    accountLoginTwoFactor: {
      padding: 20,
      boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.16)',
    },
    accountLoginTwoFactorTitle: {
      marginBottom: 10,
    },
    accountLoginTwoFactorSubTitle: {
      color: theme.custom.darkGray,
      fontSize: 14,
      marginBottom: 20,
    },
    accountLoginTwoFactorItem: {
      display: 'flex',
      alignItems: 'center',
    },
    accountLoginTwoFactorItemContent: {
      flexGrow: 1,
      [theme.breakpoints.up('lg')]: {
        display: 'flex',
      },
    },
    accountLoginTwoFactorItemLogo: {
      verticalAlign: 'top',
      marginBottom: 10,
      [theme.breakpoints.up('lg')]: {
        marginBottom: 0,
        marginRight: 10,
      },
    },
    accountLoginTwoFactorItemInfo: {
      flexGrow: 1,
    },
    accountLoginTwoFactorItemName: {
      marginBottom: 0,
    },
    accountLoginTwoFactorItemNote: {
      fontSize: 14,
      color: theme.custom.darkestGray,
    },
    accountLoginTwoFactorItemState: {
      marginLeft: 10,
      marginRight: -25,
      minWidth: 100,
    },
    // Login code.
    accountDialogLoginCode: {
      position: 'relative',
    },
    accountDialogLoginCodeTitle: {
      color: theme.custom.darkGray,
      marginBottom: 40,
      [theme.breakpoints.up('lg')]: {
        marginBottom: 55,
      },
    },
    accountDialogLoginCodeField: {
      [theme.breakpoints.up('lg')]: {
        maxWidth: 360,
      },
    },
    accountDialogLoginCodeError: {
      color: theme.custom.errorRed,
      marginBottom: 10,
    },
    accountDialogLoginCodeTrouble: {
      marginTop: 30,
      textAlign: 'center',
      '& a': {
        fontWeight: 'inherit',
        color: theme.custom.facebookBlue,
      },
      [theme.breakpoints.up('lg')]: {
        textAlign: 'left',
      },
    },
    // Login email verification.
    accountDialogLoginEmailVerification: {
      position: 'relative',
    },
    accountDialogLoginEmailVerificationTitle: {
      fontSize: 24,
      marginBottom: 16,
    },
    // Login social disconnect.
    accountDialogLoginSocialDisconnect: {
      position: 'relative',
    },
    accountDialogLoginSocialDisconnectTitle: {
      fontSize: 24,
    },
    // Login two factor authentication.
    accountDialogLoginAuth: {
      position: 'relative',
      '& span': {
        color: theme.custom.darkGray,
      },
      [theme.breakpoints.up('lg')]: {
        paddingRight: 280,
      },
    },
    accountDialogLoginAuthInfo: {
      position: 'relative',
    },
    accountDialogLoginAuthTitle: {
      fontSize: 24,
      marginTop: 30,
      marginBottom: 10,
    },
    accountDialogLoginAuthImg: {
      marginTop: 25,
      marginBottom: 25,
      '& img': {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      [theme.breakpoints.up('lg')]: {
        position: 'absolute',
        left: '100%',
        top: 0,
        width: 280,
        margin: 0,
      },
    },
    accountDialogLoginAuthField: {
      marginTop: 20,
    },
    // Select members.
    accountDialogSelectMembersTitle: {
      fontSize: 32,
      lineHeight: 1.2,
      letterSpacing: '-0.05em',
      marginBottom: 16,
      [theme.breakpoints.up('lg')]: {
        fontSize: 48,
      },
    },
    accountDialogSelectMembersSubTitle: {
      marginBottom: 16,
    },
    // Unsubscribe - Confirm.
    accountUnsubscribeConfirmWrapper: {
      padding: '30px 0',
    },
    accountUnsubscribeConfirmTitle: {
      marginBottom: 30,
      [theme.breakpoints.up('lg')]: {
        marginBottom: 41,
      },
    },
    accountUnsubscribeConfirmForm: {
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: custom.border,
      borderRadius: 4,
      padding: 15,
      [theme.breakpoints.up('lg')]: {
        padding: 25,
      },
    },
    // Admin styles.
    adminWrapper: {
      position: 'relative',
    },
    adminHeader: {
      height: 50,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: theme.custom.slate,
      color: theme.custom.white,
      padding: '10px 20px',
      [theme.breakpoints.up('lg')]: {
        height: 78,
        padding: '10px 20px 10px 50px',
      },
    },
    adminHeaderLogo: {
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.custom.white,
      width: 55,
      height: 30,
      [theme.breakpoints.up('lg')]: {
        borderWidth: 3,
        width: 98,
        height: 56,
      },
    },
    adminHeaderInfo: {
      display: 'flex',
      alignItems: 'center',
    },
    adminHeaderImg: {
      fontSize: 24,
      marginRight: 8,
      [theme.breakpoints.up('lg')]: {
        fontSize: 42,
      },
    },
    adminContentWrapper: {
      position: 'relative',
      overflowX: 'hidden',
      padding: theme.spacing(5, 0),
    },
    adminContentInner: {
      position: 'relative',
      margin: '0 auto',
      padding: '0 15px',
      [theme.breakpoints.up('lg')]: {
        padding: '0 20px',
        display: 'flex',
      },
    },
    adminSidebar: {
      [theme.breakpoints.up('lg')]: {
        width: 160,
        marginRight: 20,
        flexShrink: 0,
      },
    },
    adminSidebarNavBack: {
      '& .link': {
        display: 'flex',
        alignItems: 'center',
        fontSize: 18,
        paddingBottom: 12,
        marginBottom: 24,
        color: theme.custom.slate,
        textDecoration: 'underline',
        '&:hover': {
          color: theme.custom.slate,
          textDecoration: 'none',
        },
      },
    },
    adminSidebarNav: {
      '& .link': {
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
        color: theme.custom.darkGray,
        padding: '2px 0 2px 10px',
        marginBottom: 24,
        borderLeftWidth: 2,
        borderLeftStyle: 'solid',
        borderLeftColor: 'transparent',
        '&:hover': {
          color: theme.custom.slate,
        },
        '&.active': {
          borderLeftColor: theme.custom.gold,
          color: theme.custom.slate,
        },
      },
      '& .icon': {
        fontSize: 16,
        marginRight: 10,
      },
    },
    adminContent: {
      [theme.breakpoints.up('lg')]: {
        flexGrow: 0,
        width: 'calc(100% - 180px)',
      },
    },
    adminTitleWrapper: {
      marginBottom: 30,
      [theme.breakpoints.up('lg')]: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 41,
        '& $adminTitle': {
          marginBottom: 0,
        },
        '& $adminFieldWrapper': {
          minWidth: 280,
        },
      },
    },
    adminTitle: {
      marginBottom: 30,
      [theme.breakpoints.up('lg')]: {
        display: 'block',
        marginBottom: 41,
      },
    },
    adminFieldWrapper: {
      padding: 0,
    },
    // Admin form full.
    adminFormFull: {
      position: 'relative',
    },
    adminFormFullSearchInput: {
      position: 'relative',
      marginBottom: 20,
    },
    adminFormFullSearchBtn: {
      color: theme.custom.slate,
      position: 'absolute',
      right: 0,
      top: 0,
      padding: 19,
      borderRadius: 0,
      '& .icon': {
        fontSize: 16,
      },
    },
    adminFormFullResetBtn: {
      color: theme.custom.slate,
      position: 'absolute',
      right: 40,
      top: 0,
      padding: 19,
      borderRadius: 0,
      '& .icon': {
        fontSize: 16,
      },
    },
    adminFormFullFiltersResultPaper: {
      padding: theme.spacing(1),
    },
    adminFormFullFiltersResults: {
      [theme.breakpoints.up('lg')]: {
        display: 'flex',
        alignItems: 'flex-start',
      },
    },
    adminFormFullFilters: {
      marginBottom: 20,
      backgroundColor: theme.custom.lightGrayDark,
      padding: 20,
      [theme.breakpoints.up('lg')]: {
        width: 224,
        marginRight: 40,
        flexShrink: 0,
      },
    },
    adminFormFullFiltersTitle: {
      color: theme.custom.black,
      fontWeight: 700,
      fontSize: 18,
    },
    adminFormFullFiltersBlock: {
      marginTop: 30,
    },
    adminFormFullFiltersBlockTitle: {
      fontSize: 12,
      fontFamily: theme.custom.akkuratMono,
      letterSpacing: '0.16em',
      textTransform: 'uppercase',
      display: 'flex',
      alignItems: 'center',
      '& .icon': {
        fontSize: 16,
        marginRight: 9,
      },
    },
    adminFormFullFiltersBlockContent: {
      marginTop: 5,
    },
    adminFormFullResults: {
      [theme.breakpoints.up('lg')]: {
        width: 'calc(100% - 264px)',
      },
    },
    adminFormFullTableContainer: {
      position: 'relative',
    },
    adminFormFullTable: {
      '& thead th': {
        fontSize: 12,
        fontWeight: 400,
        borderBottomColor: theme.custom.lightGrayDark,
        lineHeight: 'inherit',
        '&:first-child': {
          paddingLeft: 0,
        },
        '&:last-child': {
          paddingRight: 0,
        },
      },
      '& tbody tr.Mui-selected, & tbody tr.Mui-selected:hover': {
        backgroundColor: theme.custom.lightGrayDark,
      },
      '& tbody th': {
        fontSize: 10,
        borderBottomColor: theme.custom.lightGrayDark,
        paddingLeft: 0,
      },
      '& tbody td': {
        fontSize: 10,
        color: theme.custom.darkestGray,
        borderBottomColor: theme.custom.lightGrayDark,
        '&:last-child': {
          paddingRight: 0,
        },
      },
      '& tbody a': {
        color: theme.custom.facebookBlue,
      },
    },
    adminFormFullTableCloseBtn: {
      color: theme.custom.errorRed,
      padding: 0,
      '& .icon': {
        fontSize: 16,
      },
    },
    // Admin programs.
    adminProgramsBlock: {
      marginBottom: 40,
      maxWidth: 800,
    },
    adminProgramsBlockTitle: {
      fontSize: 24,
    },
    adminProgramsBlockInfo: {
      marginTop: 23,
    },
    adminProgramsBlockBtn: {
      marginTop: 23,
    },
    // Admin program.
    adminProgramBackBtn: {
      marginBottom: 36,
    },
    adminProgramInfo: {
      marginBottom: 50,
    },
    adminProgramSearch: {
      display: 'flex',
      marginBottom: 20,
    },
    adminProgramSearchInput: {
      position: 'relative',
      flexGrow: 1,
    },
    adminProgramSearchBtn: {
      color: theme.custom.slate,
      position: 'absolute',
      right: 0,
      top: 0,
      padding: 19,
      borderRadius: 0,
      '& .icon': {
        fontSize: 16,
      },
    },
    adminProgramSearchMoreBtn: {
      flexShrink: 0,
      marginLeft: 10,
    },
    adminProgramFooter: {
      paddingTop: 20,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    // Remove user.
    adminDialogProgramRemoveUser: {
      textAlign: 'center',
    },
    adminDialogProgramRemoveUserTitle: {
      marginBottom: 45,
    },
    // Add user.
    adminDialogProgramAddUser: {
      padding: 0,
    },
    adminDialogProgramAddUserManually: {
      padding: 0,
    },
    adminDialogProgramAddUserManuallyMembers: {
      padding: 0,
    },
    adminDialogProgramAddUserManuallyMember: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 8,
    },
    adminDialogProgramAddUserManuallyMemberImg: {
      fontSize: 40,
      flexShrink: 0,
    },
    adminDialogProgramAddUserManuallyMemberContent: {
      marginLeft: 10,
      fontSize: 14,
      flexGrow: 1,
      '& span': {
        display: 'block',
        color: theme.custom.darkestGray,
        fontSize: 10,
        fontFamily: theme.custom.akkuratMono,
        letterSpacing: '0.16em',
        textTransform: 'uppercase',
      },
    },
    adminDialogProgramAddUserManuallyMemberBtn: {
      marginLeft: 10,
      color: theme.custom.errorRed,
      padding: 0,
      '& .icon': {
        fontSize: 16,
        stroke: 'currentColor',
        strokeWidth: 2,
      },
    },
    // Admin verification.
    adminVerificationTime: {
      fontSize: 12,
      display: 'flex',
      alignItems: 'center',
      '& .icon': {
        fontSize: 12,
        marginRight: 6,
      },
      '&.green': {
        color: theme.custom.successGreen,
      },
      '&.gold': {
        color: theme.custom.gold,
      },
      '&.red': {
        color: theme.custom.errorRed,
      },
    },
    // Admin invoices.
    adminInvoiceStatus: {
      fontSize: 12,
      display: 'inline-flex',
      alignItems: 'center',
      '& .icon': {
        fontSize: 12,
        marginLeft: 6,
      },
      '&.green .icon': {
        color: theme.custom.successGreen,
      },
      '&.red .icon': {
        color: theme.custom.errorRed,
      },
    },
    // Fulfillment.
    fulfillmentSearchFormWrapper: {
      maxWidth: '100%',
      backgroundColor: theme.custom.white,
      borderRadius: '2px',
      marginTop: '24px',
      padding: '12px 24px',
      fontWeight: 'bold',
      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.10)',
    },
    fulfillmentSearchForm: {
      display: 'flex',
      gap: '24px',
      flexWrap: 'wrap',
    },
    fulfillmentSearchFormTitle: {
      display: 'block',
      fontFamily: theme.custom.akkuratMono,
      textTransform: 'uppercase',
      fontSize: 12,
      fontWeight: 400,
      lineHeight: 1.5834,
      letterSpacing: '0.16em',
      marginBottom: '8px',
    },
    fulfillmentSearchFormSelectWrapper: {
      borderColor: theme.custom.slate,
      color: theme.custom.slate,
      '& svg': {
        color: theme.custom.slate,
      },
    },
    fulfillmentSearchFormSelect: {
      minWidth: '176px',
      color: theme.custom.slate,
    },
    fulfillmentSearchFormInput: {
      borderColor: theme.custom.mediumGray,
      color: theme.custom.slate,
    },
    fulfillmentTextarea: {
      borderColor: theme.custom.mediumGray,
      color: theme.custom.slate,
      width: '100% !important',
      marginBottom: 24,
      padding: '12px 16px',
    },
    fulfillmentSearchFormInputWrapper: {
      position: 'relative',
      borderColor: theme.custom.mediumGray,
      flex: '1 0 auto',
      '& fieldset': {
        borderColor: theme.custom.mediumGray,
      },
    },
    fulfillmentSearchFormPagination: {
      flexShrink: 0,
      marginLeft: 'auto',
      '& > div': {
        borderLeft: '1px solid',
        borderLeftColor: theme.custom.slate,
        borderRadius: 0,
        marginLeft: 24,
        paddingLeft: 12,
      },
      '& button': {
        padding: '0 12px',
        textDecoration: 'none',
        fontSize: 18,
        fontWeight: 'normal',
        color: theme.custom.slate,
        '&:last-child': {
          marginRight: 0,
          paddingRight: 0,
        },
        '&:hover': {
          backgroundColor: 'transparent',
          textDecoration: 'underline',
        },
        '& svg': {
          color: theme.custom.slate,
        },
      },
    },
    fulfillmentSearchFormTable: {
      borderCollapse: 'separate',
      borderSpacing: '2px',
      borderRadius: '4px',
      boxShadow: 'none',
      '& thead': {
        borderRadius: '4px 0',
      },
      '& th': {
        background: theme.custom.darkGray,
        color: theme.custom.white,
        padding: '10px 16px',
        fontWeight: 'bold',
      },
      '& td': {
        background: theme.custom.lightestGray,
        padding: '16px',
        borderBottom: '0 none',
        boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.15)',
        '&.error': {
          background: 'rgba(255, 60, 95, 0.12)',
        },
        '& .error': {
          color: theme.custom.errorRed,
        },
      },
    },
    fulfillmentSearchFormTableHeadDark: {
      background: `${theme.custom.darkestGray} !important`,
    },
    fulfillmentSearchFormTableLoadingData: {
      opacity: '0.5',
    },
    FulfillmentTableBatchDetailsHeader: {
      fontSize: '18px',
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
      margin: '24px 0 48px',
      color: theme.custom.slate,
    },
    FulfillmentTableBatchDetailsNotes: {
      fontSize: '18px',
      textDecoration: 'none',
      marginLeft: '24px',
      color: theme.custom.slate,
    },
    FulfillmentTableBatchFilterToggle: {
      fontSize: '18px',
      textDecoration: 'none',
      marginLeft: '0px',
      color: theme.custom.slate,
    },
    // Customer List Dashboard.
    customerSearchFormWrapper: {
      maxWidth: '100%',
      backgroundColor: theme.custom.white,
      borderRadius: '2px',
      marginTop: '24px',
      padding: '12px 24px',
      fontWeight: 'bold',
      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.10)',
    },
    customerSearchForm: {
      display: 'flex',
      gap: '24px',
      flexWrap: 'wrap',
    },
    customerSearchFormTitle: {
      display: 'block',
      fontFamily: theme.custom.akkuratMono,
      textTransform: 'uppercase',
      fontSize: 12,
      fontWeight: 400,
      lineHeight: 1.5834,
      letterSpacing: '0.16em',
      marginBottom: '15px',
    },
    customerSearchFormInputLabel: {
      display: 'block',
      fontFamily: theme.custom.akkuratMono,
      fontSize: 12,
      fontWeight: 400,
      lineHeight: 1.5834,
      letterSpacing: '0.16em',
      marginBottom: '8px',
    },
    customerSearchFormSectionWrapper: {
      display: 'flex',
      gap: '24px',
      flexWrap: 'wrap',
    },
    customerSearchFormSectionSearchInputItem: {
      width: '65%',
    },
    customerSearchFormSectionClearBtnItem: {
      width: '25%',
    },
    customerSearchFormSelectWrapper: {
      borderColor: theme.custom.slate,
      color: theme.custom.slate,
      '& svg': {
        color: theme.custom.slate,
      },
    },
    customerSearchFormSelect: {
      minWidth: '176px',
      color: theme.custom.slate,
    },
    customerSearchFormInput: {
      borderColor: theme.custom.mediumGray,
      color: theme.custom.slate,
    },
    customerSearchFormInputWrapper: {
      position: 'relative',
      borderColor: theme.custom.mediumGray,
      flex: '1 0 auto',
      '& fieldset': {
        borderColor: theme.custom.mediumGray,
      },
    },
    customerSearchFormMessage: {
      marginTop: '10px',
      fontSize: '0.875rem',
      color: '#6b6b6b',
      fontStyle: 'italic',
    },
    customerSearchFormTable: {
      borderCollapse: 'separate',
      borderSpacing: '2px',
      borderRadius: '4px',
      boxShadow: 'none',
      '& thead': {
        borderRadius: '4px 0',
      },
      '& th': {
        background: theme.custom.darkGray,
        color: theme.custom.white,
        padding: '10px 16px',
        fontWeight: 'bold',
      },
      '& td': {
        background: theme.custom.lightestGray,
        padding: '16px',
        borderBottom: '0 none',
        boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.15)',
        '&.error': {
          background: 'rgba(255, 60, 95, 0.12)',
        },
        '& .error': {
          color: theme.custom.errorRed,
        },
      },
    },
    customerTableRecordActions: {
      fontSize: '16px',
      fontWeight: 'normal',
      paddingRight: '8px',
      color: theme.custom.slate,
      '& svg': {
        color: theme.custom.slate,
      },
      '& a': {
        color: theme.custom.slate,
        flex: '1 0 auto',
        '&:hover, &:focus': {
          color: theme.custom.white,
        },
      },
    },
    paginationWrapper: {
      marginTop: '16px',
      display: 'flex',
      justifyContent: 'left',
    },
    // Box static page layout.
    boxPage: {
      maxWidth: 494,
      padding: '38px 60px 22px',
      margin: '86px auto',
      boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.25)',
    },
    boxPageTitle: {
      fontSize: 32,
      lineHeight: 1.2,
      color: theme.custom.slate,
      textAlign: 'center',
    },
    boxPageSubTitle: {
      fontSize: 14,
      lineHeight: 1.2,
      padding: 10,
      color: theme.custom.slate,
      textAlign: 'center',
    },
    boxPageContent: {
      fontSize: 16,
      color: theme.custom.darkGray,
      '& ul': {
        marginTop: 24,
        marginBottom: 48,
      },
      '& li': {
        marginBottom: 16,
      },
    },
    boxPageActions: {
      textAlign: 'center',
    },
    // Agent styles.
    agentBox: {
      display: 'block',
      width: '100%',
      maxWidth: theme.breakpoints.values.md,
      margin: '0 auto',
      [theme.breakpoints.up('md')]: {
        borderRadius: 4,
        padding: 32,
        boxShadow:
          'rgba(0, 0, 0, 0.2) 0px 11px 15px, rgba(0, 0, 0, 0.12) 0px 9px 46px, rgba(0, 0, 0, 0.14) 0px 24px 38px',
      },
    },
    agentStatus: {
      padding: 5,
      background: 'transparent',
      borderBottomWidth: 5,
      borderBottomStyle: 'solid',
      marginBottom: 40,
      '@media (min-width: 768px)': {
        display: 'flex',
      },
    },
    agentStatusTrue: {
      background: alpha(String(theme.custom.successGreen), 0.12),
      borderBottomColor: theme.custom.successGreen,
    },
    agentStatusFalse: {
      background: alpha(String(theme.custom.errorRed), 0.12),
      borderBottomColor: theme.custom.errorRed,
    },
    agentFieldText: {
      '& input': {
        background: 'transparent',
      },
    },
    agentFieldTextError: {
      '& input': {
        background: theme.custom.errorRed,
      },
    },
    agentFormWrapper: {
      display: 'flex',
      columnGap: 10,
      '& > div': {
        width: '100%',
      },
    },
  });
};
export default styles;
