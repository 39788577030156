import { MemberInfoOutput } from './api/client';

type User = {
  id: string;
  firstname: string;
  lastname: string;
  email: string;
  roles: string[];
};

/**
 * Account class.
 */
class Account {
  /**
   * Logged in user.
   */
  private user?: User;

  /**
   * Member info response DTO.
   */
  private member_info?: MemberInfoOutput;

  /**
   * Logged in masked user.
   */
  private firebase_user?: User;

  /**
   * Logged in masked user.
   */
  private masked_user?: User;

  /**
   * Gets a user.
   */
  getUser(masqueraded?: boolean): null | User {
    return (masqueraded ? this.masked_user : this.user) ?? null;
  }

  /**
   * Sets logged it user.
   */
  setUser(user: User, member_info: MemberInfoOutput, masqueraded?: boolean) {
    const id = this.firebase_user?.id;
    if (masqueraded && id && id !== user.id) {
      this.masked_user = this.firebase_user;
    }
    this.user = user;
    this.member_info = member_info;
  }

  /**
   * Sets logged it user from Firebase.
   */
  setUserFromFb(user: User) {
    this.firebase_user = user;
  }

  /**
   * Checks whether the user is administrator.
   */
  roleAdmin() {
    const adminRoles = ['administrator', 'cs_super_agent', 'customer_support'];
    return (
      adminRoles.some((role) => this.hasRole(role, this.masked_user)) || adminRoles.some((role) => this.hasRole(role))
    );
  }

  /**
   * Checks whether the user is agent_app_user.
   */
  roleAgent() {
    return this.hasRole('agent_app_user', this.masked_user) || this.hasRole('agent_app_user');
  }

  /**
   * Checks whether the user is super administrator.
   */
  roleSuperAdmin() {
    return this.hasRole('administrator', this.masked_user) || this.hasRole('administrator');
  }

  /**
   * Checks whether the user has a specific role.
   */
  hasRole(role: string, user?: User) {
    return !!(user ?? this.user)?.roles.includes(role);
  }

  /**
   * Checks whether the user is VA member.
   */
  roleVa() {
    return !!this.user?.roles.filter((role) => role.startsWith('va_') && !role.startsWith('vr_')).length;
  }

  /**
   * Checks whether the user is VR member.
   */
  roleVr() {
    return !!this.user?.roles.filter((role) => role.startsWith('vr_')).length;
  }

  /**
   * Checks whether the user is Household member.
   */
  roleHhm() {
    return !!this.member_info?.household_member;
  }
}

export default new Account();
