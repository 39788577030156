import custom from '../custom';

export default {
  styleOverrides: {
    root: {
      borderRadius: 2,
      padding: '13px 16px',
    },
    text: {
      fontSize: 16,
      lineHeight: 1.5,
      textDecoration: 'underline',
      padding: '4px 0',
      minWidth: 0,
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '&.Mui-disabled': {
        color: custom.mediumGray,
      },
    },
    contained: {
      boxShadow: 'none',
      '&:hover': {
        boxShadow: 'none',
      },
      '&.Mui-disabled': {
        backgroundColor: custom.mediumGray,
        color: custom.white,
      },
    },
    outlined: {
      padding: '12px 16px',
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: custom.white,
      },
      '&.Mui-disabled': {
        borderColor: custom.mediumGray,
        color: custom.mediumGray,
      },
    },
    outlinedPrimary: {
      color: custom.slate,
      borderColor: custom.slate,
      '&:hover': {
        backgroundColor: custom.white,
      },
    },
    containedSizeSmall: {
      fontSize: 14,
      lineHeight: 1.572,
      padding: '8px 16px',
    },
    sizeSmall: {
      fontSize: 14,
      lineHeight: 1.572,
    },
    outlinedSizeSmall: {
      fontSize: 14,
      lineHeight: 1.572,
      padding: '7px 16px',
    },
    secondary: {
      color: custom.slate,
      borderColor: custom.gold,
    },
  },
};
